.switch-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-date {
  display: flex;

  .input-group-addon {
    background: #E3E7EE;
  }

  .search-input input {
    width: 370px;
  }
}

.switch-map {
  margin-top: 16px;
  margin-left: 0px;
  width: 194px;
  display: flex;
  align-items: center;
  border: 1px solid #E3E7EE;
  box-sizing: border-box;
  border-radius: 6px;
  box-sizing: border-box;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    text-align: center;
    color: #8591A6;
    box-sizing: border-box;
    height: 35px;
    width: 97px;
    line-height: 37px;
    transition: 0.4s;
    cursor: pointer;

    &:hover {
      background: #3E85F9 !important;
      color: #fff !important;
    }
  }
}

.active-table {
  background: #3E85F9 !important;
  color: #fff !important;
}

.icon-map,
.icon-month,
.week-btn {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.icon-table,
.icon-year,
.year-btn {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.input-sets {
  .k-datepicker {
    width: 100%;
  }

  input {
    width: 224px;
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #8591A6;
    padding: 0 47px 0 10px;
    background-color: #FFFFFF !important;
    border: 1px solid #E3E7EE;
    box-sizing: border-box;
    border-radius: 6px !important;
    height: 37px;
    line-height: 37px;
    z-index: 1;
  }

  .input-group-addon {
    background-color: #3E85F9;
    border: 1px solid #E3E7EE;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    height: 37px;
    width: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #3E85F9;
    border: 1px solid #E3E7EE;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    height: 37px;
    width: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .k-datepicker {
    width: 100%;
  }
}

.tab-date .input-group-addon {
  background: #E3E7EE;
}

.tab-date {
  display: flex;
  align-items: center;

  .switch-map {
    margin-right: 10px;
  }
}

.search-input {
  position: relative;
  margin-left: 40px;

  .search-icon {
    position: absolute;
    right: 12px;
    top: 10px;
  }

  .search-input {
    background: #F4F6FB;
    border-radius: 25px;
    width: 258px;
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 110.4%;
    display: flex;
    align-items: center;
    color: #C6C8CC;
    height: 37px;
    line-height: 37px;
    border: none;
    display: block;
    padding: 0 42px 0 17px;
  }
}

.table-content {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  overflow-x: auto;
  padding-left: 20px;

  table {

    thead,
    tfoot {
      background: #F4F6FB;
      border-radius: 4px;
    }

    thead tr td,
    tfoot tr td {
      font-weight: 500;
      color: #8591A6;
      padding: 10px 20px;
    }

    tbody tr td {
      color: #596980;
      padding: 10px 20px;
      border-bottom: 1px solid #F4F6FB;

      a {
        color: #3E85F9;
        padding: 0 5px;

        &:hover {
          color: #3E85F9;
          padding: 0 5px;
        }
      }

      span {
        font-size: 12px;
      }
    }
  }
}

.bg-white-organizations {
  background: #FFFFFF;
  border-radius: 20px;
  margin-right: 20px;
  padding: 20px;
}

.organizations-thead {
  background: #F4F6FB;
  color: #8591a6;
  font-family: 'Source Sans Pro', sans-serif;
  /*font-weight: 400;*/
  border-bottom: 2px solid #F4F6FB !important;
  border-top: 1px solid #F4F6FB !important;
  border-radius: 5rem !important;
  font-size: 14px;
}

.organizations-tbody {
  color: #596980;
  font-family: 'Source Sans Pro', sans-serif;
  /*font-weight: 600;*/
  border-bottom: 2px solid #F4F6FB !important;
  border-top: 1px solid #F4F6FB !important;
  border-radius: 5rem !important;
}

.organizations-tbody td,
.organizations-thead td {
  min-width: 135px;
  max-width: 135px;
  word-break: break-all;
}

.organizations-tbody tr td.td-35,
.organizations-thead td.td-35 {
  min-width: 35px;
  max-width: 35px;
}

.organizations-tbody td.td-110,
.organizations-thead td.td-110 {
  min-width: 110px;
  max-width: 110px;
}

.organizations-tbody td.td-115,
.organizations-thead td.td-115 {
  min-width: 115px;
  max-width: 115px;
}

.organizations-tbody td.td-70,
.organizations-thead td.td-70 {
  min-width: 70px;
  max-width: 70px;
}

.organizations-tbody td.td-100,
.organizations-thead td.td-100 {
  min-width: 100px;
  max-width: 100px;
}

.organizations-tbody td:last-child,
.organizations-thead td:last-child {
  min-width: 75px;
  max-width: 75px;
}

//   li {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 11px;
//     text-align: center;
//     color: #8591A6;
//     box-sizing: border-box;
//     height: 35px;
//     width: 97px;
//     line-height: 37px;
//     transition: 0.4s;
//     cursor: pointer;

.org-client-head {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 14px;
  color: #8591a6;
  margin: 0;
}

// .active-table {
//   background: #3E85F9 !important;
//   color: #fff !important;
// }

.org-td {
  // width: 1.25em !important;
  padding: 10px 5px !important;
  margin: 0;
  font-size: 14px !important;
}

.org-create-button {
  margin-right: 20px !important;
}

.org-action center img {
  margin: 0 5px;
  cursor: pointer;
}

.org-action {
  .org-invitation {
    display: flex;
    justify-content: flex-end;
  }

  center {
    &.org-invitation {
      display: flex;
      justify-content: flex-end;

      img {
        margin: 0 8px;
      }
    }
  }
}

.org-head {
  cursor: pointer !important;
  padding: 10px 5px !important;
  color: #8591a6;
}

.org-datepicker {
  right: 0px !important;
  height: 35px !important;
}

.del-row {
  text-decoration: line-through solid red;
}

.input-sets-org-dates input {
  width: 223px !important;
}

.input-sets-org-dates {
  width: 224px;
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #8591A6;
  padding: 0 47px 0 10px;
  background-color: #FFFFFF !important;
  border: 1px solid #E3E7EE;
  box-sizing: border-box;
  border-radius: 6px !important;
  height: 37px;
  line-height: 37px;
  z-index: 1;
}

.input-sets .k-datepicker #date-range-picker {
  width: 224px !important;
}

.org-search-input {
  overflow: hidden;
  border-radius: 25px;
}

.org-search-input input {
  max-width: 370px;
  width: 100%;
}

.margin-search {
  margin-top: -13px !important;
}

span.org-tooltip-wrapper {
  position: relative;
}

.org-tooltip-header {
  display: none;
  position: absolute;
  top: 20px;
  z-index: 10;
  background-color: #596980;
  color: white;
  padding: 5%;
  border-radius: 5px;
  left: 0.1px;
  min-width: 180px;
  text-align: center;
}

.header-tooltip-hover:hover .org-tooltip-header {
  display: inherit;
}