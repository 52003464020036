.background-jobs-container {
  margin: 0;
  flex: 5 1;
  background: #f4f6fb;
  height: calc(100vh - 110px);
  padding: 0 30px 0 0;
  width: calc(100% - 290px);
  overflow-y: auto;
}

.backgroundJobs-table {
  width: 100%;
  background-color: #FFFFFF;
  color: #8c939d;
  border: #f5f6fb 1px solid;

  th.backgroundJobs-head,
  td {
    text-align: left;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}

.backgroundJobs-table-top {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #FFFFFF;
  min-height: 100%;
}

.backgroundJobs-head {
  background-color: #f5f6fb;
  padding-left: 0.5rem;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.backgroundJobs-tbody{
  td{
    font-size: 14px;
    padding-left: 0.5rem;
  }
}

//Details
.backgroundJobs-detail-page {
  margin-top: 1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
  background-color: #FFFFFF;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  color: #97a1af;
}

.button-container {
  margin-top: 10%;
  margin-left: 90%;
  background-color: #3e85f9;
  color: white;
  border: none;
  height: 2rem;
  width: 6rem;
  border-radius: 8px;
}
.button-container:hover {
  background-color: #0a62ef;
  color: white;
}

.details-flex-container {
  display: flex;
  justify-content: space-between;
}

.form-control-background-jobs {
  border-radius: 6px;
  border: 1px solid #d3d3d3;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #5b6a83;
  padding: 0.375rem 0.75rem;
  margin-right: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 18rem;
  background-color: white;
  height: 39px;
}

.parameters-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.parameter-group {
  display: flex;
  align-items: center;
}

//Description
.description-card {
  background-color: #f5f6fb;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 30%;
  margin-right: 0.2rem;
  height: 15rem;
  max-width: 40%;
}

@media screen and (max-width: 960px) {
  .description-card {
    width: 90%;
  }
}

.description-title {
  margin-bottom: 16px;
  color: #8c939d;
  font-size: 24px;
}

.description-text {
  color: #596980;
  line-height: 1.6;
}

.description-card {
  position: relative;
  padding-bottom: 3rem; 
}

.description-textarea {
  width: 100%;
  height: 100%;
  padding: 10px; 
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none; 
}

.runjob-btn {
  position: relative;
  display: flex;
  justify-content: flex-end; 
  margin-right: 0.2rem;

  @media (max-width: 1500px) {
  margin-right: 0.2rem; 
  }
}

.save-button, .edit-button {
  position: absolute;
  bottom: -2.5rem;
  left: 89%;
  transform: translateX(-50%);
  background-color: #3e85f9;
  color: white;
  border: none;
  height: 2rem;
  width: 6rem;
  border-radius: 8px;
  z-index: 1000;

  @media (max-width: 1500px) {
    left: 86%;
  }
}

//History
.backgroundJobs-history-table-top {
  margin-top: 1rem;
  background-color: #FFFFFF;
  border-radius: 2%;
  min-height: 20%;
}

.backgroundJobs-detail-history {
  background-color: #FFFFFF;
  padding: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundJobs-detail-history h3 {
  background-color: #FFFFFF;
}

.lower-header-backgroundJobs-hisotry {
  margin-left: 1rem;
}

.backgroundJobs-table tbody tr:hover {
  background-color: #ccc;
}

.failed-result {
  color: red;
}

.success-result {
  color: green;
}

//#region tooltip

/* Tooltip wrapper */
.info-icon-wrapper {
  margin-left: 5px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.tooltip-container {
  position: absolute;
  top: 50%; 
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px; 
  padding: 8px;
  background-color: #f9f9f9;
  color: black;
  border: 1px solid #ddd;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

//#endregion