.mui-section-container {
  position: relative;
  top: 3.5rem;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.mui-section-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.mui-bg {
  background-color: #F4F6FB;
}

/* MATERIAL UI COLUMNS */
.mui-column-four {
  padding-right: 5rem;
}

/* MATERIAL UI TOP ROWS */
.mui-top-row-two {
  position: relative;
}

.mui-top-row-three {
  position: relative;
  left: 10px;
}

.mui-top-row-four {
  position: relative;
  left: 30px;
}

/* MATERIAL UI MIDDLE ROWS */
.mui-middle-row-two {
  position: relative;
}

.mui-middle-row-three {
  position: relative;
}

.mui-middle-row-four {
  position: relative;
}

/* MATERIAL UI DROPDOWN ICON */
.mui-dropdown-icon {
  font-size: 2.6rem;
  align-self: flex-end;
  transform: translateY(5px);
  transform: translateX(90px);
}

/* MATERIAL UI DROPDOWN CONTAINER */
.mui-collapse-container {
  border: 1px solid #D6DAE0 !important;
  border-top: 1px solid #D6DAE0 !important;
  border-radius: 6px !important;
  margin-left: -0.43rem;
  margin-right: -0.41rem;
  transform: translateY(-3.1rem);
  height: 17.1rem;
  padding: 0 1rem;
}

.MuiCollapse-wrapper {
  height: 14rem;
  cursor: pointer;
}

.MuiTableCell-root {
  color: #596980 !important;
  font-size: 14px !important;
  padding: 0 0.25rem !important;
}

/* MATERIAL UI PARAGRAPHS */
.mui-p, .mui-p-top {
  color: #596980;
  font-family: SF Pro Display !important;
  font-weight: normal;
  font-size: 14px;
  margin: auto;
}

.mui-p {
  width: 10rem;
}

/* MATERIAL UI SPAN */
.mui-span-req-amount {
  color: #3E85F9;
  font-family: SF Pro Display;
  font-weight: 700;
  font-size: 14px;
}

.mui-span-acc-amount {
  color: #00C48C;
  font-family: SF Pro Display;
  font-weight: 700;
  font-size: 14px;
}

.mui-span-pen-amount {
  color: #F2994A;
  font-family: SF Pro Display;
  font-weight: 700;
  font-size: 14px;
}

.mui-span-ave-amount {
  color: #596980;
  font-family: SF Pro Display;
  font-weight: 700;
  font-size: 14px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #596980;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}