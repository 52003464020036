.bank-status-bar {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  height: 100%;

  .bank-data-node-container {
    margin: 0;
    width: 100%;
    padding: 2px 0;
    position: relative;
    animation: loadIn 0.3s 0.3s ease-in-out 1 forwards;
    max-height: 0;
    font-size: 14px;

    .bank-data-node-inner {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin: 0;
      height: 100%;
      width: 100%;

      .bank-data-node {
        min-width: 2px;
        border-radius: 50% / 10%;
        max-width: 16px;
        margin: 1px;
        width: 100%;
        height: 100%;
        font-size: 10px;
        color: black;
        display: inline-block;
        background-color: rgb(150, 150, 150);

      }

      .bank-data-node-color-green {
        background-color: rgb(121, 240, 121);
      }

      .bank-data-node-color-yellow {
        background-color: rgb(239, 190, 100);
      }

      .bank-data-node-color-red {
        background-color: rgb(255, 125, 125);
      }

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}

@keyframes loadIn {
  0% {
    max-height: 0;

  }

  1% {
    max-height: 0;

  }

  99% {
    max-height: 50px;
  }

  100% {
    max-height: 100%;
  }
}