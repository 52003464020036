.login_form {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #f4f6fb;
  justify-content: space-between;
  font-family: 'Noto Sans', sans-serif;
}

.login-logo {
  text-align: center;
  margin: 60px 0 40px;
}

.left-cloud {
  position: absolute;
  z-index: 0;
  left: 130px;
  top: 56px;
}

.right-cloud {
  position: absolute;
  z-index: 0;
  right: 0;
  top: 45px;
}

.left-man {
  position: absolute;
  z-index: 0;
  left: 20px;
  top: 143px;
}

.right-man {
  position: absolute;
  z-index: 0;
  right: 10px;
  top: 140px;
}

.login_form_set {
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  width: 700px;
  min-height: 760px;
  box-sizing: border-box;
  padding: 60px 15px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-woman {
  position: absolute;
  z-index: 0;
  left: -255px;
  bottom: 40px;
}

.right-bottom-man {
  position: absolute;
  right: -245px;
  bottom: 40px;
}

.bottom-leaves {
  position: absolute;
  z-index: 0;
  right: -307px;
  bottom: 0;
}

.welcomeHeader {
  font-weight: bold;
  font-size: 36px;
  line-height: 75px;
  color: #2B3B54;
  margin: 0 0 27px;
  text-align: center;
}

.plzLogin {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #5B6A83;
  text-align: center;
}

.plzLogin span {
  display: block;
}

.otp-event {
  margin: 0 auto;
  max-width: 400px;
}

.arrow-back-home {
  top: 27px;
  left: 22px;
  position: absolute;
}

.arrow-back-home a {
  display: flex;
  color: #596980;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
}
.arrow-back-home a img {
  margin: 0 14px 0 0;
}

.full-set-login {
  width: 100%;
  display: flex;
  max-height: 100%;
  min-height: 100vh;
  background: #F6F9FC;
}

.set-login-left {
  height: 100vh;
  padding: 64px 70px;
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-box-pack: center;
  justify-content: center;
  width: calc(100% - 575px);
}

#kerdits_logo {
  position: absolute;
  left: 70px;
  top: 40px;
  z-index: 9;
}

.set-login-left figure {
  width: 100%;
  margin: 50px 0 0 0;
}

.set-login-left figure img {
  width: 100%;
}

.set-login-right {
  position: relative;
  width: 575px;
  min-height: 100vh;
  max-height: 100%;
  padding: 46px 25px 0;
  box-sizing: border-box;
  background: #fff;
}

.set-login-right-top-50 {
  padding-top: 50px;
}

.login-form-set {
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
}

.arrow-back-home {
  margin: 0 0 44px;
}

.login-form-set h2 {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #151522;
  margin: 0;
}

.login-form-set h6 {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 15px 0 30px;
  font-size: 12px;
  line-height: 20px;
  color: #828E99;
}

.login-form-set h6 span {
  color: #425466;
  font-size: 16px;
}

.single-label-inputs {
  display: flex;
  margin: 0 0 10px;
  position: relative;
  align-items: center;
  justify-content: space-between;
}  

.single-label-inputs input {
  width: 57px;
  height: 54px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 24px;
  color: #000000;
}

.otp-submition {
  display: flex;
  margin: 10px 0 48px;
  align-items: center;
  justify-content: space-between;
}

.label-check {
  display: flex;
  color: #596980;
  font-size: 12px;
  line-height: 16px;
  margin: 13px 0 0 0;
  align-items: center;
}

.label-check input {
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
}

.login_submit {
  width: 166px;
  background: #0065FF;
  border-radius: 6px;
  border: none;
  height: 45px;
  line-height: 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF !important;
  cursor: pointer;
}

.login_submit_disabled {
  color: #fff;
  background: #8591a6;
  border-color: #8591a6;
  cursor: not-allowed;
  pointer-events: none;
}

.resend-otp-timer span {
  margin: 0 0 0 11px;
}

.resend-otp-timer span, .resend-otp-timer a {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #667380;
  text-decoration: none;
}

.resend-otp-timer a {
  cursor: not-allowed;
}

.resend-button-active{
  cursor: pointer;
}

.is_disabled {
  color: #667380 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.resend_button {
  color: #065fe7;
}

.resend-otp-timer {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

#resend_button {
  color: #065fe7;
}

.verify_by_email {
  text-align: center;
}

.verify_by_email a {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #3e85f9;
  text-decoration: none;
}

.copyright_bottom {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #828E99;
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
}
.authenticate-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(31,39,51,80%);
}
.inner-auth-modal {
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}
.continue-btn {
  color: #ffffff !important;
  background: #3E85F9 !important;
}
.invalid{
  color: #FF647C;
  font-size: 14px;
  line-height: 17pxs;
}

/*=================================================================*/

.mb-20 {
  margin-bottom: 20px;
}