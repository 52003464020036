.api-popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.468);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .conversion-confirm-box {
    position: relative;
    margin: 0 auto;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    overflow: auto;
    min-height: 150px;
    border-radius: 20px;
    font-family: SF Pro Display;
    width: 300px;
    font-family: SF Pro Display;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    p {
      width: 200px;
      text-align: center;
      color: #596980;
      font-size: 16px;
    }
  }
}

.update-api-btn {
  height: 35px;
  background-color: #3e85f9;
  border: none;
  border-radius: 3px;
  color: white;
  padding: 0px 5px;
}