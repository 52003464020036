.date-picker-container {
  color: #6c757d;
  padding: 0 0 0 12px;
  font-size: 0.9rem;
  height: 35px;
  width: 12rem;

  .date-form-box {
    cursor: pointer;
    padding-right: 40px;
  }

  .ld-calendar-icon {
    background-color: #3e85f9;
    margin-left: 5px;
    margin-right: 30px;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 35px;
    height: 32px;
    padding: 3.5px 14.5px 2.5px 10.5px;
    cursor: pointer;
  }
}

.total-clients-box {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  width: 100%;

  .request-info {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    color: #8591a6;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 200px;

    p {
      margin-bottom: 0px;
    }
  }
}