body {
  margin: 0;
  font-family: SF Pro Display !important;
}

code {
  font-family: SF Pro Display !important;
}
.capitalize{
  text-transform: capitalize;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./fonts/t/0b51833ff62e4af8acb5fd3e2bf59e97.woff) format('woff');  
}