.access-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0;
  z-index: 9998;
}

.access-code-popup-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  height: 400px;
  position: fixed;
  top: 20%;
  left: 30%;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.5);
}

.modal-title-access {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;

}

.modal-text-access {
  display: flex;
  flex-direction: row;
  color: #5C697E;
  /* justify-content: center; */
  padding-left: 70px;
}

.modal-btns-wrapper-access-code {
  display: flex;
  margin: 10px 0 0;
  padding: 20px 0 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.yes-btn {
  width: 120px;
  height: 36px;
  color: white;
  line-height: 34px;
  border-radius: 7px;
  background-color: #3E85F9;
  border: 1px solid #3E85F9;
  font-size: 14px;
  margin-left: 15px;
}

.cancel-btn {
  width: 120px;
  height: 36px;
  color: #3E85F9;
  line-height: 34px;
  border-radius: 7px;
  border: 1px solid #3E85F9;
  background-color: white;
  font-size: 14px;
  margin-right: 15px;
}