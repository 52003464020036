.error-msg {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: red;
  margin: 0;
  position: relative;
  bottom: 15px;
}

.login-form input {
  color: #000;
  width: 100%;
  border: none;
  height: 40px;
  display: block;
  background: #fff;
  margin: 0 0 24px;
  line-height: 40px;
  border-radius: 20px;
  position: relative;
  padding: 0 10px 0 30px;
  box-sizing: border-box;
  box-shadow: 0px 5px 16px rgb(50 50 71 / 8%), 0px 10px 32px rgb(50 50 71 / 8%) !important;
}

.login-form button {
  color: #fff;
  width: 100%;
  border: none;
  height: 40px;
  display: block;
  line-height: 40px;
  position: relative;
  text-align: center;
  border-radius: 20px;
  background: #3E85F9;
  padding: 0 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
}