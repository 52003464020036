.lower-header-a-u {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.flex-header-account-users {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: right;
  margin-top: 10px;
  margin-right: -21px;
}

.date-form-box {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  color: #596980;
  font-size: 12px;
  font-weight: 500;
  height: 32px !important;
}

.filter-button {
  padding: 0px 40px;
  margin-right: 20px;
  width: 120px;
}

.clicked-filter-button {
  background-color: #e8edfa;
  color: #3e85f9;
  padding: 0px 40px;
  margin-right: 20px;
  width: 120px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
}

.date-form-box {
  padding-left: 10px;
  margin-right: -31px;
  width: 200px;
  outline: none;
}

.calendar-icon-statistics {
  background-color: #3e85f9;
  margin-left: -0px;
  margin-right: 25px;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 30px;
  padding: 2.5px 0px 2.5px 7.5px;
}

.vista-users-table {
  font-size: 14px;
  color: #596980 !important;
}

.vista-account-users-table-header {
  color: #596980;
  font-size: 14px;
  border-bottom: none !important;
  padding-top: 8px;
  padding-bottom: 5px;
}

.table-header-au {
  border-style: none !important;
}

.users-list-grid {
  display: grid;
  grid-template-columns: 0.9fr 5fr 2fr 2fr 3fr 4fr;
  border-bottom: 1px solid #eeeaea !important;
  background-color: #fff;
}

.vista-account-users-table-header.users-list-grid th {
  background-color: #F4F6FB;
}

.vista-account-users-table-header.users-list-grid {
  background-color: #F4F6FB;
}

.users-id-column-five {
  justify-self: center;
}

.users-id-column-six {
  justify-self: center;
}

.users-list-grid>td {
  border-bottom: none;
  color: #596980 !important;
}

.header-column-three,
.header-column-four {
  cursor: pointer;
}

.header-column-three:hover,
.header-column-four:hover {
  background-color: #dfe2eb;
  border-radius: 5px;
}

.header-column-five {
  text-align: center;
}

.vista-users-table-header {
  background-color: #f4f6fb;
  border-bottom: 2px solid transparent;
}

.table-header-au {
  background-color: #f4f6fb !important;
  color: #596980 !important;
}