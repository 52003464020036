.bank-status-query {
  margin-top: 12px;
  margin-right: 52px;

  .bank-status-query-select {
    width: 180px;
    text-transform: capitalize;
  }

  .bank-status-query-text-container {
    position: relative;
    width: 280px;
    height: 38px;

    .bank-status-query-text {
      height: 100%;
      width: 100%;
      border-radius: 5% / 50%;
      background-color: rgb(248, 248, 248);
      position: relative;


    }

    .bank-status-query-submit {
      border-style: none;
      position: absolute;
      right: -18px;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
      background: none;
      height: 36px;
      width: 36px;

      &:hover {

        filter: brightness(0.8);
        opacity: 0.8;
        rotate: 5deg;
        scale: 1.05;
      }

      .bank-status-query-text-icon {}
    }
  }
}