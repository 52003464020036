.header {
  flex: 1;
  background-color: #f4f6fb;
  height: 80px;
  width: 100%;
  display: flex;

  /* align-items: center; */
  text-align: center;

  /* justify-content: space-between; */
}

.inner-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 20px;

  .header-logo {
    width: 150px;
    cursor: pointer;
  }
}

.right-side,
.profile-user {
  display: flex;
  align-items: center;
}

.profile-user {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.uner-info {
  min-width: 150px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-relation {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 0px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #a3adbf;
  margin-right: 10px;
  margin-top: 10px;
}

.user-name {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 0px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #5b6a83;
  margin-right: 10px;
}

.user-id {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 0px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #a3adbf;
  margin-right: 10px;
}

.user-profile-pic {
  width: 40px;
  margin: 0 0 0 15px;
}

.default-language {
  min-width: 108px;
  width: 108px;
  height: 30px;
  line-height: 30px;
  background: #fff;
  border: none;
  padding: 0 14px 0 5px !important;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #596980;
  border: none !important;
  text-transform: capitalize;
  box-shadow: 0 4px 4px rgb(50 50 71 / 8%);
  border-radius: 4px;
  margin: 20px 20px 30px 0;
}

.flag {
  border-radius: 10px;
  margin-right: 10px;
}

.mark {
  height: 52.66px;
  padding: 0 !important;
}

.titleize {
  text-transform: capitalize;
}

#lang-div {
  display: none;
}