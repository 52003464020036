// COLOURS
$base-dark-grey:#596980;
$base-light-grey: #8591A6;
$base-blue: #3E85F9;
$base-green: #43BF58;
$base-white: #FFFFFF; 

// FONT SIZES
$fs-heading: 30px;
$fs-title: 20px;
$fs-subtitle: 18px;
$fs-body: 14px;
$fs-bread: 13px;
$fs-small:10px;

// FONT WEIGHTS
$fw-light: 300;
$fw-regular: 500;
$fw-bold: 600;

