.bank-status-bank-label {
  position: absolute;
  top: 4px;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;

  .bank-status-bank-label-icon {
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 32px;
    background-color: rgb(160, 30, 50);
    transform: translateY(-3px);
    margin-right: 16px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .bank-status-bank-label-img {
      height: 31px;
      width: 31px;
    }
  }

  .bank-status-bank-label-text {
    display: block;
    position: relative;
    top: 50%;
    height: 18px;
    transform: translateY(-50%);
  }

  .bank-status-bank-label-uptime {
    height: 18px;
    position: absolute;
    right: 46px;
    top: 50%;
    transform: translateY(-50%);
  }

  .bank-status-bank-label-current-status-container {
    height: 100%;
    aspect-ratio: 1 / 1;
    position: absolute;
    right: 0;
    //transform: translateY(2px);


    .bank-status-bank-label-current-status {
      height: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      background-color: rgb(124, 124, 124);
      //transform: translateY(2px);
      position: relative;

      filter: brightness(1);

      &:hover {
        filter: brightness(1.15);
      }
    }

    .bank-status-bank-label-current-status-color-green {
      background-color: rgb(71, 187, 71);
    }

    .bank-status-bank-label-current-status-color-yellow {
      background-color: rgb(223, 177, 91);
    }

    .bank-status-bank-label-current-status-color-red {
      background-color: rgb(236, 72, 72);
    }
  }


}