.transaction1 {
    position: fixed;
    height: 100%;
    width:100%;
    top: 0px;
    right: 0;
    display: flex;
    z-index: 9999; 
    background-color: rgba(0,0,0, 0.5);
    .transaction_inner1 {
        width: 450px;
        padding: 0 0 40px;
        margin: auto;
        background: #fff;
        border: 1px solid rgb(236, 232, 232);
        border-radius: 10px;
        box-shadow: gray;
        .tableContainer {
            margin: 40px 40px;
            align-items: center;
            table {
                width: 100%;
                tr td {
                    border-width: 1px;
                    border-color: #dee2e6;
                    border-style: solid;
                    vertical-align: top;
                    text-align: center;
                    color: #5b6a83;
                    text-align: left;
                    padding: 8px;
                    &:first-child {
                        min-width: 80px;
                    }
                    &.wordBreak{
                        word-break: break-all;

                    }
                }
            }
        }
        .main{
            margin: 40px 80px;
            .title {
                text-align: center;
                font-size: 18px;
                color: #5b6a83;
                margin-top: 10px;
                margin: 0;
            }
            .certificate-no{
                text-align: center;
                font-size: 20px;
                color: #5b6a83;
                font-weight: bold;
            }
        }
        .btn-div{
            align-items: center;
            justify-content: space-around;
            display: flex;
            margin: 0 60px;
            .yes-btn{
                width: 140px;
                height: 36px;
                border-radius: 7px;
                border: 1px solid #3E85F9;
                color:#fff;
                background-color: #3E85F9;
                font-size: 14px;
            }
            .no-btn{
                width: 140px;
                height: 36px;
                border-radius: 7px;
                border: 1px solid #3E85F9;
                color:#3E85F9;
                background-color: white;
                font-size: 14px;
            }
        }
      }
    .inner{
        height: 285px;
    }
  }