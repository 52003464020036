.page-container {
  flex: 5;
  background-color: #f4f6fb;
  height: calc(100vh - 110px);

}

.alert-shown {
  opacity: 1;
  transition: all 200ms ease-in-out;
  display: block;

}

.alert-hidden {
  opacity: 0;
  display: none;
}

/* # yearly */
.ld-main-data-container {
  @include flexCenter(row);
  justify-content: space-between;

  .ld-requests-container {
    width: 63%;
    border-radius: 10px;
  }

  //STYLING FOR CHART STARTS HERE
  .ld-request-chart-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 21px;

    .ld-request-chart-header {
      padding: 20px 20px 0 20px;
      display: flex;
      flex-direction: column;

      .ld-request-chart-header-filter-row {
        display: flex;
        justify-content: space-between;
      }
    }

    .ld-request-chart {
      margin-left: 30px;
    }

    .highcharts-container {
      width: 98% !important;
      height: 380px !important;
    }

    .highcharts-root {
      width: 98% !important;
    }
  }

  //STYLING FOR TABLE STARTS HERE
  .ld-request-table-container {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 21px;
    display: flex;
    flex-direction: column;

    .ld-request-table {
      border-collapse: collapse;
      position: relative;
      width: 90%;
      height: 37px;
      left: 6%;
    }

    .ld-request-table-header {
      padding: 20px;
    }

    .ld-request-table-head {
      color: $base-light-grey;
      font-size: $fs-body;
      font-weight: $fw-regular;
      background-color: #f4f6fb;

      th {
        color: $base-light-grey;
        padding: 10px 20px;
        border-radius: 4px;
        width: 20%;
        border-top: none;
        border-bottom: none;
      }

      .filter-icon {
        display: inline;
        padding-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .ld-request-table-row {
      td {
        padding: 14px 14px 14px 20px;
        font-size: $fs-body;
        font-weight: 400;
        color: $base-dark-grey;
        border: solid #f4f6fb;
        border-width: 1px 0;
      }
    }
  }

  //RECENT ACTIVITIES, RIGHT SIDEBAR, STARTS HERE
  .ld-recent-activities-sidebar-container {
    width: 35%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;

    .active ul li:nth-child(-n + 2) {
      background: #f4f6fb;
      padding: 20px 12px 0px 12px;
      width: 106%;
      position: relative;
      left: -10px;
    }

    .active ul li:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .active ul li:nth-child(2) {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-bottom: 10px;
    }

    .ld-recent-activities-sidebar-header {
      @include flexSpaceBetween;
      margin-bottom: 9px;
    }

    .ld-recent-activities-ul-list {
      position: relative;
      padding-left: 0;
    }

    .ld-recent-activities-list-items {
      @include flexSpaceBetween;
      list-style: none;
      border-bottom: 1px solid #f4f6fb;
      padding: 16px 0;
    }

    .ld-recent-activities-list-item {
      //margin-left: 14px;
      @include flexSpaceBetween;

      p,
      span {
        font-size: $fs-body;
        font-weight: 400;
        margin-bottom: $fs-body;
        color: $base-dark-grey;
        display: inline;
      }

      .list-icon {
        padding-right: 10px;
        position: relative;
        text-align: center;
        color: white;
      }

      .centered {
        position: absolute;
        top: 32%;
        left: 36%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 11px;
        letter-spacing: 0.5px;
        margin-bottom: 0;
      }

      .ld-recent-activities-desciption-box {
        padding-top: 5px;
      }
    }

    .ld-recent-activities-last-update {

      //margin-right: 14px;
      p {
        font-size: 11px;
        color: $base-light-grey;
      }
    }

    .tooltip-icon:hover {
      cursor: pointer;
    }

    .ld-tooltip {
      position: relative;
      display: inline-block;

      .ld-tooltiptext {
        visibility: hidden;
        width: 145px;
        background-color: #586989e9;
        color: #fff;
        text-align: center;
        padding: 7px 5px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        font-size: 13px;
        top: -5px;
        right: 105%;
      }
    }

    .ld-tooltip:hover .ld-tooltiptext {
      visibility: visible;
    }

    .ld-list-description {
      color: $base-light-grey;
      font-size: $fs-bread;
      font-weight: 400;
      line-height: 15.51px;
      margin: 0;
    }
  }
}

.clients-table {
  cursor: pointer;
}