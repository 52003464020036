.header-form-container {
  display: flex;
  justify-content: space-between;
}

#bar-chart-container * {
  transition: none !important;
}

.btn .genericMap-small-icon {
  font-size: 0.7rem !important;
  margin-left: -0.1rem !important;
  margin-bottom: 0.3rem !important;
}

//__________________________________________________________
//Select form
.map-ld-search-container {
  display: flex;
  justify-content: flex-start;
}

.map-ld-form-container {
  display: flex;
  align-items: center;
}

.map-custom-select {
  min-width: 10rem;
  margin-right: -5rem;


  select {
    height: 37px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 6px;
    border: 1px solid #e3e7ee;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5b6a83;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    &:focus {
      outline: none;
      border: 1px solid #62a4eb;
    }
  }
}

@media (min-width: 768px) {
  .map-ld-search-container {
    margin-right: 5rem;
  }

  .map-custom-select {
    width: auto;
  }
}

@media(min-width: 2000px) {
  .map-controls {
    position: absolute;
    top: auto !important;
    bottom: 1rem !important;
    right: auto !important;
    left: 1rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1040;
  }
}


//__________________________________________________________
//ShowDetails 
.show-details-container {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 15rem;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  z-index: 1050;
  color: #868588;
  border: solid #484a4e;
  font-size: 1rem;
  min-height: 60%;

  @media (min-width: 2000px) {
    top: 9.5px;
    left: auto;
    right: 0;
    padding: 50px;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.65) 0px 0 25px;
    border: none;
    border-left: 2px solid #706b72;
    background-color: rgba(0, 0, 0, 0.3);
    width: 54rem;
    font-size: 4rem;
    height: 100%;
  }
}

.show-details-container {
  select {
    width: 100%;
    margin: 5px 0;
    padding: 5px;
  }

  .country-list {
    margin-top: 10px;

    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
}

.show-details-select {
  background-color: #212328;
  color: #b1b2b3;
}

.border-showDetails {
  border: 1px solid #312f32;
  border-radius: 10px;
}

.show-details-toggle {
  position: absolute;
  top: 2%;
  left: 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 9.5rem;
  padding: 10px 20px;
  border-radius: 20px;
  color: #d0d0d1;
  font-size: 0.9rem;
  z-index: 1040;

  @media (min-width: 2000px) {
    top: auto;
    bottom: 1.5%;
    left: auto;
    right: 0;
    width: 16rem;
    font-size: 1.8rem;
  }
}

.show-details-icon {
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #5c697e;
  color: white;
  border: 1px solid white;

  @media (min-width: 2000px) {
    rotate: 180deg;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 0.1rem;
  }
}

.show-details-icon-up {
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #5c697e;
  color: white;
  border: 1px solid white;
  margin-top: 1rem;

  @media (min-width: 2000px) {
    width: 2.5rem;
    height: 2.5rem;
    animation: rotate-up 0.25s ease-out 0s forwards;
  }
}

@keyframes rotate-up {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.show-details-toggle-up {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 2%;
  right: 2%;

  @media (min-width: 2000px) {
    bottom: 3%;
    right: 4%;
  }
}

.hidden {
  visibility: hidden;
}

.show-details-today-title{
  font-size: 1.8rem;
  color: #ffffff;

  @media (min-width: 2000px) {
    font-size: 4rem;
    margin-bottom: 3rem;
    margin-top: 3rem !important;
  }
}

.show-filters-button-position {
  position: absolute;
  top: 1%;
  right: 3%;
}

.show-filters-button{
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;

  @media (min-width: 2000px) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

//Bubble animation
@keyframes growShrink {
  0%, 70% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.my-animated-bubble {
  animation: growShrink 3s ease-in-out;
}
