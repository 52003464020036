.new-password-h4{
    margin-bottom: 3rem;
    font-size: 1.2rem !important;
    color: #596989 !important;
}

.new-password-p{
    font-size: 1rem;
    color: #596989;
    text-align: center;
    margin-bottom: 2rem;
}

.new-password-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }
  
  .new-password-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 25%;
    height: 25%;

    @media (max-width: 1500px) {
      width: 35%;
      height: 35%;
    }
  }

  .new-password-modal-content h2 {
    margin-bottom: 2rem;
    color: #333;
  }
  
  .new-password-modal-content p {
    font-size: 1rem;
    color: #596989;
    text-align: center;
  }
  
  .new-password-modal-content button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
  }