.bg-white {
  background: #FFFFFF;
  border-radius: 20px;
  padding-bottom: 10px;
  margin-right: 20px;
}

.bg-20-top {
  padding-top: 20px;
}

.name-value-list li {
  display: flex;
  align-items: flex-start;

  img {
    margin: 4px 8px 0 0;
  }

  .name-value {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #5B6A83;
    width: calc(100% - 24px);
    padding: 0 0 11px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #ECEEF2;

    label {
      font-weight: normal;
    }

    p {
      font-weight: bold;
    }
  }

  span img {
    margin: 0 0 0 6px;
  }
}

.change-pass {
  text-decoration: none;
  color: #3E85F9;
  font-weight: 400!important;
  font-family: SF Pro Display !important;
}

.basic-head {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #596980;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}