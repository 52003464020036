.organizations-tbody td {
  vertical-align: middle;
}

.word-break {
  word-break: break-all;
}

.email-img-cover {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50px;
}

.email-img-cover img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.tab-date .search-input input.search-kreditz-provider {
  color: #8591a6;
  background: #ffffff;
}