.statistics-container {
  flex: 5;
  height: 100%;
  background-color: #f4f6fb;
  overflow-y: scroll;

  .lower-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #596980;

    .flex-header {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: right;
      margin-top: 10px;

      .filter-button,
      .date-form-box {
        background-color: white;
        border-radius: 5px;
        border: 1px solid #d3d3d3;
        color: #596980;
        font-size: 12px;
        font-weight: 500;
        height: 32px !important;
      }

      .filter-button {
        padding: 0px 40px;
        margin-right: 20px;
        width: 120px;
      }

      .clicked-filter-button {
        background-color: #e8edfa;
        color: #3e85f9;
        padding: 0px 40px;
        margin-right: 20px;
        width: 120px;
        border-radius: 5px;
        border: 1px solid #d3d3d3;
        font-size: 12px;
        font-weight: 500;
        height: 32px;
      }

      .date-form-box {
        padding-left: 10px;
        margin-right: -31px;
        width: 200px;
        outline: none;
      }

      .calendar-icon-statistics {
        background-color: #3e85f9;
        margin-left: -0px;
        margin-right: 25px;
        border-top: 1px solid #d3d3d3;
        border-bottom: 1px solid #d3d3d3;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 30px;
        padding: 2.5px 0px 2.5px 7.5px;
      }
    }
  }

  .filters-box {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    width: 100%;
    height: 50px;
    margin-left: -15px;

    .react-select-custom {
      height: 32px;

      &:focus {
        outline: none;
      }

      div {
        margin: -1px 0 0;
        font-size: 12px;
      }

      .css-13cymwt-control {
        border-color: lightgray;
        min-height: 32px;
        height: 32px;

        &:focus {
          border-color: lightgray;
          outline: none;
        }
      }

      .css-t3ipsp-control {
        box-shadow: none;
        border-color: lightgray;
        height: 32px;
        min-height: 32px;

        &:hover {
          outline: none;
          border-color: lightgray;
          box-shadow: none;
          height: 32px;
          min-height: 32px;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border-color: lightgray;
          height: 32px;
        }

        &:focus-visible {
          border-color: lightgray;
          box-shadow: none;
          outline: none;
          height: 32px;
        }
      }

      #react-select-2-placeholder {
        margin: -5px 0 0;
        font-size: 12px;
      }

      div[data-value] {
        font-size: 12px;
      }
    }

    .each-fliter-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      color: #5b6a83;
      margin: 0px 10px 0px 15px;
      overflow: hidden;

      .select-filter {
        position: relative;
        height: 40px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid lightgray;
        background-color: white;
        color: #3f495b;
        outline: none;
      }

      .apply-filter-button {
        height: 40px;
        margin-top: 18px;
        background-color: #3e85f9;
        border: none;
        border-radius: 3px;
        color: white;
      }
    }
  }

  .main-statistics-box {
    display: flex;
    flex-direction: row;
    position: relative;

    .statistics-graf {
      position: relative;
      width: 100%;
      background-color: white;
      padding: 30px;
      border-radius: 10px;
      margin-top: 20px;
      color: #5b6a83 !important;
    }

    .requests-box {
      display: flex;
      flex-direction: column;
      position: relative;

      .each-requests-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 180px;
        height: 70px;
        margin: 20px 25px 0px 20px;
        border-radius: 10px;
        padding: 10px;
        font-size: 12px;
        font-weight: 400;

        .request-info {
          display: flex;
          flex-direction: column;
          color: #5b6a83;
          margin: 10px 0px 0px 10px;
          padding: 0px;

          p {
            margin: 0px;
          }
        }
      }
    }
  }
}

section.accepted-requests-section {
  // position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin: 20px 30px 30px 0px;
  height: fit-content;
  color: #596980;
}

div.graph-btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;

  button.trend-btn {
    background-color: #3E85F9;
    color: white;
    border: 2px solid #3E85F9;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
  }

  button.graph-btn {
    background-color: white;
    color: #3E85F9;
    border: 2px solid #3E85F9;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
  }

  button.actve-graph-type {
    background-color: #3E85F9;
    color: white;
  }

  button.graph-btn-first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  button.graph-btn-middle {
    border-radius: 0;
  }

  button.graph-btn-last {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }

  button.trend-btn {
    margin: 5px;
  }

  button.graph-btn:hover {
    background-color: #3E85F9;
    color: white;
  }

  button.trend-btn:hover {
    background-color: white;
    color: #3E85F9;
    border: 2px solid #3E85F9;
  }
}

.customer-activity-icon {
  background-color: #3e85f9;
  margin-left: -0px;
  margin-right: 30px;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 30px;
  padding: 2.5px 0px 2.5px 7.5px;
}

h5.active-customers-chart-heading {
  text-align: center;
  color: #3e85f9;
  margin-bottom: 20px;
}

.active-customers-section {
  display: flex;
  flex-direction: row;

  .active-customer-charts {
    min-width: 50%;
  }

  .active-customer-charts:nth-child(1) {
    align-self: center;
    min-width: 55%;
  }

  .active-customer-charts:nth-child(2) {
    min-width: 45%;
  }
}

.total-requests-style {
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
}