.bank-status-hover-popup-container {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  opacity: 1;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, 100%);
  max-height: 1000px;

  .bank-status-hover-arrow {
    position: relative;
    margin-bottom: -6px;
    z-index: 31;
  }

  .bank-status-hover-border {

    animation-name: grow;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    max-height: 500px;
    background-color: white;
    border: 2px solid grey;
    border-top: none;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    top: 0;

    .bank-status-hover-border-top {
      position: absolute;
      background-color: grey;
      height: 2px;

      top: 0;
    }

    .bank-status-hover-border-top-left {
      left: 0;
    }

    .bank-status-hover-border-top-right {
      right: 0;
    }

    .bank-status-hover-popup-text {
      margin: 0;
      animation-name: textFadeIn;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
      text-wrap: nowrap;
    }
  }

  .bank-status-hover-popup-errors {
    transition: all 0.4s ease-in-out;
    max-height: 24px;
    height: 24px;
  }

  .bank-status-hover-popup-errors-messages {
    max-height: 140px;
    height: 100%;
    width: 100%;

    .bank-status-hover-popup-error-box {
      border-top: 1px solid rgb(248, 248, 248);

      animation: errorAni 0.5s ease-in-out 0.1s 1 forwards;
      opacity: 0;
      max-height: 0;
      margin: 0;
      margin-top: 4px;
      padding: 2px;
      min-width: 100%;

      .bank-status-hover-popup-error-text {
        font-size: 11px;
        margin: 0;
      }
    }
  }

  .bank-status-hover-popup-no-errors {
    max-height: 18px;
    height: 18px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;

  }

}

@keyframes errorAni {
  from {
    opacity: 0;
    max-height: 0;
    color: rgba(92, 105, 126, 0);


  }

  to {
    opacity: 1;
    color: rgba(92, 105, 126, 1);
    max-height: 42px;

  }

}

@keyframes textFadeIn {
  from {
    color: rgba(92, 105, 126, 0);

  }

  to {

    color: rgba(92, 105, 126, 1);


  }

}


@keyframes grow {
  from {
    max-height: 0;
  }

  to {

    max-height: 240px;

  }

}