.api-popup-container {
  display: flex;
  justify-content: center;
}

.lower-header-api {
  display: flex;
  justify-content: space-between;

  span.arrow-heading-wrapper-editor {
    display: flex;
    min-width: 40%;
  }

}