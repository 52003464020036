.DeletePopup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
}

.DeletePopup_inner {
    position: absolute;
    width: 380px;
    height: 350px;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: #fff;
    border: 1px solid rgb(236, 232, 232);
    border-radius: 10px;
    padding: 20px;
}

.trash {
    margin-top: 40px;
    margin-left: 150px;
    width: 48px;
    height: 58px;
}

.firstText {
    text-align: center;
    font-size: 18px;
    color: #5b6a83;
    margin-top: 10px;
}

.secondText {
    text-align: center;
    font-size: 18px;
    color: #5b6a83;
    margin-top: -20px;
}

.closeDelete {
    margin: 10px 0px 0px 50px;
    width: 100px;
    height: 36px;
    border-radius: 7px;
    border: 1px solid #3E85F9;
    color: #3E85F9;
    background-color: white;
    font-size: 14px;
}

.userName {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #5b6a83;
    margin-top: -20px;
}

.confirm {
    margin: 20px 10px 0px 30px;
    width: 100px;
    height: 36px;
    border-radius: 7px;
    background-color: rgb(233, 68, 68);
    border: 1px solid #e94444;
    color: white;
    font-size: 14px;
}