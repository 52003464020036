// Utilities
@import './utilities/utilities';
.df-main{
    display: flex;
    margin-bottom: 25px;
    flex-direction: row;
    font-weight: 500;
    width: 100%;
    .df-left{
      flex: 4;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      color: #596980;
      display: flex;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .bottom{
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #596980;
        display: flex;
        align-items: center;
        margin: 0;
        a{
          color:#3e85f9;
          cursor: pointer;
          text-decoration: none;
        }
        .arrow{
          margin: 0 6px;
          height: 8px;
        }
      }
    }
    .df-right{
      flex: 1;
      .ld-search-container{
        display: flex;
        justify-content: flex-end;
        margin-right: 20px ;
        height: 37px;
        width: auto;
        .df-form-container {
          display: flex;
          justify-content: space-between;
          height: 37px;
          #search {
            background: url('https://api.iconify.design/mdi/magnify.svg?color=%239ca5b4&width=22') no-repeat scroll 15rem 6px;
            background-color: white !important;
            border: 1px solid #e3e7ee;
            color: #6c757d;
            font-style: italic;
            font-size: 12px;
            border-radius: 5px;
            margin-right: 11px;
            width: 17rem;
            height: 37px;
            padding: 0 42px 0 17px;
          }
        
          #search:focus {
            outline: none;
          }
        }
        
        #df-form-container {
          .ld-form-input-field,
          #form-date-field {
            border-radius: 6px;
            border: 1px solid #e3e7ee;
            height: 37px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.03em;
            color: #5b6a83;
            padding: 0.375rem 0.75rem;
            margin-right: 10px;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            width: 190px;
            background-color: white;
    
            &:focus {
              outline: none !important;
              border: 0.1px solid #62a4eb;
            }
          }
          #form-date-field {
            padding: 0.75rem 0 0.75rem 0.75rem;
            width: 230px;
          }
        }
        .ld-calendar-icon {
          background-color: #3e85f9;
          margin-left: -39px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          width: 30px;
          height: 37px;
          margin-top: 0;
        }
  
      }
    }
  }

  .report-table-container {
    background-color: #FFFFFF;
    margin: 15px 15px 0px 0px;
    border-radius: 10px;
    padding: 20px;
    height: calc(100vh - 168px);
    .pagination-wrapper {
      text-align: center;
      padding: 2rem  0 0 0;
      position: absolute;
      bottom: 0;
      width: calc(100% - 30px);
    }
    .sms-recent-log-table-header {
      @include flexSpaceBetween;
      margin-bottom: 17px;
      .search-bar-form {
        @include flexSpaceBetween;
        width: 258px;
        padding: 10px 17px;
        background-color: #f4f6fb;
        border-radius: 25px;
        border: none;
      }
    }
  }

  .report-table-container-scroll-y {
    height: calc(100vh - 310px);
    overflow-y: auto;
    position: relative;
  }

  .remove-icon {
    margin:  0 5px;
    padding:  0 2px;
  }
  
  .report-pagination-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 10%;
    margin: 0 auto;
    margin-bottom: 20px;
  }