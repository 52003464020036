.broker-flow-ld-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ld-header-broker-flow {
    display: flex;
    flex-direction: column;
  }

  .broker-flow-container {
    margin: 0;
    flex: 5 1;
    background: #f4f6fb;
    height: calc(100vh - 110px);
    padding: 0 30px 0 0;
    width: calc(100% - 290px);
    overflow-y: auto;
  }

  .broker-flow-table-top {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #FFFFFF;
    height: 93%;
    overflow-y: auto;
  }
  
  .broker-flow-tbody{
    td{
      padding-left: 0.5rem;
      font-size: 14px;
    }
  }
  
  .broker-flow-view-details-icon {
    text-align: right;
    width: 30px;
  }

  .broker-flow-thead {
    background-color: #f4f6fb;
    color: #8591a6;
    font-size: 14px !important;
  }

  .broker-flow-th {
  padding-left: 0.5rem;
}

.broker-flow-table {
  table-layout: fixed;
  width: 100%;
}

.broker-flow-th-id, org-td-id {
  width: 50px;
  padding-left: 0.5rem;
}

.broker-flow-flex-header {
  display: flex;
  justify-content: space-between;
}

.broker-flow-details-flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.broker-flow-date-picker-wrapper {
  display: flex;
  align-items: center;
}

.broker-flow-customer-activity-icon {
  background-color: #3e85f9 !important;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 3px 8px;
}

.broker-flow-reset-date-button{
  background-color: #8591a6;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding: 3px 8px;
  color: white;
}

.broker-flow-total-button {
  background-color: white;
  color: #3e85f9;
  border: 1px solid #3e85f9;
  height: 2rem;
  width: 10rem;
  border-radius: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;

  &.active, &:hover {
    background-color: #3e85f9;
    border-color: #3e85f9;
    color: white;
  }
}

//Details
.broker-flow-chart-details {
  display: flex;
  justify-content: center;
}

.broker-flow-chart-select{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: -0.6rem;
}


//Chart
.broker-flow-chart{
  margin-top: 4rem;
  margin-bottom: 2rem;
}

//Message
.broker-flow-confirmation-message-container {
  width: 100%;
  top: 8.3rem;
  left: 44.2%;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  max-width: 600px;
  background: #fff;
  border-radius: 10px;
  margin: 0 0 0 -200px;
  box-shadow: 0 0 10px -8px gray;
  border: 1px solid rgb(236, 232, 232);
}

.broker-flow-flashNameHead{
  width: calc(100% - 120px);
  padding: 14px 0 10px 0;
  margin-left: 3rem;
}

.broker-flow-confirmation-message-buttons button {
  margin-left: 0.5rem;
  background-color: white;
  color: #3e85f9;
  border: 1px solid #3e85f9;
  height: 2.2rem;
  border-radius: 3px;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 14px;
  width: 10rem;
  margin-bottom: 0.5rem;

  cursor: pointer;

  &.active, &:hover {
    background-color: #3e85f9;
    border-color: #3e85f9;
    color: white;
  }
}

.broker-flow-confirmation-message-successpopup_inner{
  display: flex;
  align-items: center;
  border-left: 5px solid #49BC8D;
  flex-direction: column;
}