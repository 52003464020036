.banners-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}


.banners-actions {
  display: flex;
  margin-right: 16px;
  grid-column-start: 6;
  grid-column-end: 7;
  padding: 0.5em 0 !important;
  width: 56px;
}

.banners-actions>img {
  margin: 8px;
}


.banners-table-data-overflow {
  overflow: hidden;
  text-overflow: ellipsis;

}

.banners-table-row {
  font-size: 14px;
  margin-top: 12px;

}

.banners-template {
  width: 240px;
  max-width: 240px;
  grid-column-start: 1;
  grid-column-end: 2;

}

.banners-title {
  width: 240px;
  max-width: 240px;
  grid-column-start: 2;
  grid-column-end: 3;

}

.banners-table-hover {
  border-radius: 2em;
  height: 48px;
  align-items: center;
  min-height: 48px;

  &:hover {
    background-color: rgba(225, 225, 225, 0.4);

  }
}

.banners-text {
  grid-column-start: 3;
  grid-column-end: 4;

}

.banners-product {
  grid-column-start: 4;
  grid-column-end: 5;
}

.banners-enabled {
  grid-column-start: 5;
  grid-column-end: 6;
}

.banners-grid-container {
  display: grid;
  grid-template-columns: 4fr 4fr 6fr 2fr 2fr 1fr;
}

.banners-button {
  width: 148px;
}

.banners-form-inputs::placeholder {
  color: rgba(197, 199, 204, 1) !important;
}

.banners-checkbox-switch {
  height: 1.2em !important;
  width: 2.75em !important;

}

.banners-btns-actions {
  border: none;
  background: none;
  margin: 0.2em 0;
}

.banners-enabled-text {
  padding: 8px 20px;
  border-radius: 2em;
}

.banners-enabled-no {
  color: rgba(133, 145, 166, 1);
}

.banners-enabled-yes {

  background-color: rgba(225, 250, 227, 1);
  color: rgba(0, 149, 106, 1);
}

.banners-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(31, 39, 51, 0.8);
  z-index: 99;
}

.banners-modal-popup {
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 350px;
  min-height: 272px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 0px rgba(13, 27, 51, 0.1);
  border-radius: 2em;
  z-index: 100;
}

.banners-btns-delete {
  width: 100px;
}


.banners-table-expanded {
  text-wrap: wrap;
  overflow: visible;
  height: auto;
  background-color: rgba(225, 225, 225, 0.2);

}

.form-color-preview {
  width: 48px;
  height: 24px;
  border-radius: 24px;
}

.form-color-preview-blue {
  background-color: rgba(232, 241, 250, 1);
  border: 1px solid rgba(214, 218, 224, 1);
}

.form-color-preview-red {
  background: #FFF1F1;
  border: 1px solid rgb(224, 214, 214);
}

.form-color-preview-yellow {
  background-color: rgb(247, 250, 232);
  border: 1px solid rgb(224, 221, 214);
}

.form-icon-preview {
  width: 48px;
  height: 24px;
}

.banner-header-arrow {
  &:hover {
    cursor: pointer;
  }

}