.invitaionModal.DeletePopup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.invitaionModal .DeletePopup_inner {
  position: absolute;
  width: 600px;
  height: 440px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: #fff;
  border: 1px solid rgb(236, 232, 232);
  border-radius: 30px;
  padding: 30px 90px;
  text-align: center;
}

.invitaionModal .closeIconInvite {
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 28px;
  width: 12px;
  height: 12px;
}

.invitaionModal .firstText {
  color: #596980;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: 16px 0 10px;
  text-align: center;
}

.invitaionModal .secondText {
  text-align: center;
  font-size: 20px;
  color: #596980;
  margin: 0;
}

.invitaionModal .closeDelete {
  margin: 0 0px 0px 15px;
  width: 80px;
  height: 37px;
  border-radius: 7px;
  border: 1px solid #3E85F9;
  color: #FFFFFF;
  background-color: #3E85F9;
  font-size: 14px;
}

.invitaionModal .closeDelete:hover {
  opacity: 0.8;
}

.invitaionModal .userName {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #5b6a83;
  margin-top: -20px;
}

.invitaionModal .confirm {
  margin: 20px 10px 0px 30px;
  width: 100px;
  height: 36px;
  border-radius: 7px;
  background-color: rgb(233, 68, 68);
  border: 1px solid #e94444;
  color: white;
  font-size: 14px;
}

.invitaionModal .inviteInnerDetails {
  text-align: left;
}

.invitaionModal .invi-org-details {
  margin: 32px 0 24px;
}

.invitaionModal .invi-org-details p {
  color: #596980;
  margin: 0 0 8px;
}

.invitaionModal .invi-org-details p span {
  color: #000000;
}

.invitaionModal .invitationLink {
  margin-bottom: 20px;
}

.invitaionModal .invitationLink label {
  color: #596980;
}

.invitaionModal .invitationLink input {
  border: 1px solid #D6DAE0;
  border-radius: 6px;
  color: #3E85F9;
  font-size: 13px;
  height: 37px;
  padding: 10px 30px 10px 10px;
  width: 100%;
}

.invitaionModal .inviteInput {
  position: relative;
}

.invitaionModal .copyLink {
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 2px;
  z-index: 2;
  background: #fff;
  height: 34px;
  padding: 6px;
  border-radius: 4px;
}

.invitaionModal .custom-checkbox {
  /* align-items: center; */
  display: flex;
  height: 50px;
  justify-content: space-between;
  position: relative;
  padding-left: 35px;
}

.invitaionModal .custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  z-index: 9;
}

.invitaionModal .custom-checkbox label small {
  font-size: 13px;
  color: #8591A6;
  position: relative;
  top: -5px;
}


.invitaionModal .custom-checkbox label:before,
.invitaionModal .custom-checkbox input:checked+label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border-radius: 4px;
}

.invitaionModal .custom-checkbox label:before {
  content: "";
  border: 1px solid #C3C9D9;
  box-shadow: 3px 3px 3px 0px #353E4D33 inset;
  background: #fff;
}

.invitaionModal .custom-checkbox input:checked+label:after {
  content: "\2713";
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  background: #3E85F9;
  border: 1px solid #3E85F9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitaionModal .custom-checkbox label {
  color: #596980;
  padding-left: 0;
}

/* Hover effect for the checkbox */
.invitaionModal .custom-checkbox:hover:before {
  border-color: #999;
}

.copyLink-text {
  color: #596980;
}