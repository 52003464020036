.add-breadcrum p {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596980;
  display: flex;
  align-items: center;
  margin: 0 0 0 0;
}

.add-breadcrum p img {
  margin: 0 6px;
  width: 6px;
}

.add-breadcrum p a,
.add-breadcrum p a:hover {
  color: #3E85F9 !important;
}

.h3-basic-heading-create {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #596980;
  margin: 0 0 20px;
}

.create-client-table .form-group {
  margin-bottom: 25px;
}

.create-client-table .form-group label {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12.5px;
  line-height: 16px;
  color: #596980;
  margin: 0 0 5px;
}

.create-client-table .form-group input {
  background: #FFFFFF;
  height: 37px;
  line-height: 37px;
  padding: 0 10px;
  width: 100%;
  display: block;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.form-group select {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5B6A83;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}

a {
  text-decoration: none !important;
}

.tab-check-list ul {
  display: flex;
  align-items: center;
  border-radius: 6px;
  overflow-y: hidden;
  border: 1px solid #E3E7EE;
  margin: 0 0 40px;
  padding: 0;
}

.tab-check-list ul li {
  width: 16.66%;
  list-style-type: none;
}

.tab-check-list ul li+li {
  border-left: 1px solid #E3E7EE;
}

.tab-check-list ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #8591A6;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 12px 10px;
  text-decoration: none;
}

.active-ontab,
.tab-check-list ul li a:hover {
  background: #3E85F9 !important;
  color: #fff !important;
}

.detail-two-box {
  display: flex;
  margin: 0 -2%;
}

.details-left.details-full-box.padding-15 {
  padding-top: 15px;
}

.detail-two-box .details-full-box {
  width: 46%;
  margin: 0 2%;
}

.detail-two-box .details-full-box .form-group {
  margin: 0;
}

.detail-two-box .details-full-box .form-group label {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #596980;
}

.detail-two-box .details-full-box .form-group select {
  width: 50%;
}

.detail-two-box .details-full-box .form-group-flex {
  margin: 0 0 10px;
  padding: 0 0 10px;
}

.detail-two-box .details-full-box .form-group-flex .form-group {
  display: flex;
}

.detail-two-box .details-full-box .form-group-flex .form-group input {
  width: 150px;
  margin: 0 0 0 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5B6A83;
}

.detail-two-box .details-full-box .input-sets {
  width: 150px;
}

.bg-20-top-zero {
  padding-bottom: 0 !important;
}

.headeing-switch,
.headeing-switch-light {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #596980;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px;
  border-bottom: 1px solid #F4F6FB;
  padding: 0 0 15px;
}

.headeing-switch.sub-tab {
  padding-top: 15px;
}

.order-bottom-none {
  border: none !important;
}

.headeing-switch-light.padding-top-15 {
  padding-top: 15px;
}

.headeing-switch-light,
.adjust-switcher {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.adjust-switcher {
  display: flex;
  align-items: center;
}

.adjust-switcher .select-switch {
  display: flex;
  align-items: center;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  position: relative;
  height: 22px;
  justify-content: flex-end;
  padding: 0 64px 0 0;
  margin: 0 0 0 20px;
}

.adjust-switcher .select-switch .switch-box input {
  opacity: 0;
  width: 0;
  height: 0;
}

.adjust-switcher .select-switch .switch-box span {
  margin: 0;
}

.adjust-switcher .select-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  -webkit-transition: .4s;
  transition: .4s;
  height: 22px;
  width: 44px;
  background: #E3E7EE;
  border: 1px solid #FCFDFF;
  box-sizing: border-box;
  box-shadow: inset 0.714286px 1.42857px 4.28571px rgba(0, 33, 151, 0.25);
  border-radius: 10.1429px;
}

.adjust-switcher .select-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.adjust-switcher .select-switch input:checked+.slider {
  background: #3E85F9;
  border: 1px solid #FCFDFF;
  box-sizing: border-box;
  box-shadow: inset 0.714286px 1.42857px 4.28571px rgba(0, 33, 151, 0.25);
  border-radius: 12.2857px;
  width: 44px;
  height: 22px;
}

.adjust-switcher .select-switch input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.adjust-switcher .select-switch input:checked+.slider:before {
  transform: translateX(20px);
  background: #FCFDFF;
  border: 0.785714px solid #FCFDFF;
  box-sizing: border-box;
  box-shadow: 0.561225px 1.12245px 3.36735px rgba(0, 33, 151, 0.45);
}

.adjust-switcher .slider.round {
  border-radius: 34px;
}

.adjust-switcher .slider.round::after {
  content: '';
}

.adjust-switcher .select-switch .slider.round:before {
  border-radius: 50%;
}

.browse-file {
  width: 150px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #596980;
}

.browse-file .browse-choose-file {
  margin: 0 0 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 88px;
  background: #F4F6FB;
  border: 1px solid #E3E7EE;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  font-family: SF Pro Display;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #8591A6;
}

.browse-file .browse-choose-file img {
  margin: 0 0 10px;
}

.browse-file .browse-choose-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  background: red;
  opacity: 0;
}

.create-client-button {
  text-align: right;
}

.create-client-button button {
  border: none;
  background: #3E85F9;
  border-radius: 6px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  width: 166px;
  color: #fff;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.adjust-switcher .select-switch.dReport-dd {
  width: 120px;
}

/*=================== Create New Clients Responsive*/
@media screen and (max-width: 1250px) {
  .create-client-table .col-md-3 {
    width: 50%;
    margin: 0 0 15px;
    float: left;
  }
}

@media screen and (max-width: 768px) {
  .create-client-table .col-md-3 {
    width: 100%;
    margin: 0 0 15px;
    float: none;
  }

  .detail-two-box {
    flex-direction: column;
  }

  .detail-two-box .details-full-box {
    width: 96%;
    margin: 0 2% 15px;
  }
}

.bg-white-org {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 30px !important;
  margin: 0 0 30px;
}

.main-body {
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: calc(100% - 280px);
  float: right;
  /*margin: 60px 0 0 0;*/
  padding: 20px 30px 30px 30px;
  overflow-y: auto;
}

.basic-head01 {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #596980;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-form-control {
  height: 37px;
  line-height: 37px;
  border: 1px solid #E3E7EE;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #5B6A83;
}

.org-datepicker-agreement {
  background-color: #3E85F9;
  border: 1px solid #E3E7EE;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  height: 37px;
  width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.input-sets input {
  width: 150px !important;
}

.input-sets .input-group-addon {
  z-index: 0 !important;
}

.capitalize {
  text-transform: capitalize;
}

.org-excludeDropdown {
  outline: 0;
  box-shadow: none;
  border: 1px solid #D6DAE0 !important;
  border-radius: 5px;
  background: #fff;
  background-image: none;
  color: #5B6A83 !important;
  font-size: 13px;
  /*max-width: 276px;*/
  height: 37px;
  padding: 5px;
  padding-left: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABoSURBVHgBdc67DYAwDATQswtoWYkRmACYAJgAIUTNZwEqaBmBlWhJkRALRYoixZ31Tvahn/cMkRHjhNNrXM8yxGk7KjFW+i0YaP2QIAwaMXKnbPrWwMJk6EeVD139kP9PQrDqMFrM3z/TGyyIePS2AwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 10px 8px;
  background-position: right 12px top 12px;
  width: 100%;
}

input::placeholder {
  color: #c5c7cc99 !important;
}

.input-info-bank span {
  word-break: break-all !important;
}

.mtb-15 {
  margin-bottom: 15px !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-select {
  width: 62px !important;
}

.d-flex-select select {
  height: 37px !important;
  padding: .375rem .75rem .375rem 0.45rem !important;
}

.country-input {
  width: calc(100% - 55px) !important;
}

// ==========================================================
#inside_tab_panel {
  .nav-tabs {
    border-bottom: 1px solid #E3E7EE;

    .nav-link {
      margin: 0;
      border: none;
      color: #8591A6;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      font-style: normal;
      font-family: SF Pro Display;
      border-bottom: 2px solid #ffffff;

      &.active {
        color: #3E85F9;
        border-bottom: 2px solid #3E85F9;
      }
    }
  }

  #tab_content_certificate {
    .detailed-box {
      display: flex;
      padding: 20px 0 12px;
      align-items: flex-start;
      border-bottom: 1px solid #E3E7EE;

      .headeing-switch {
        margin: 0;
        padding: 0;
        width: 270px;
        border: none;
        color: #000000;
        font-size: 14px;
        line-height: 22px;
        padding: 0 10px 8px 0;
        box-sizing: border-box;
      }

      .head-switch {
        width: calc(100% - 270px);

        .adjust-switcher {
          display: block;
          position: relative;

          .select-switch {
            margin: 0 0 8px;
            padding: 0 0 0 56px;
            justify-content: flex-start;

            .switch-box {
              .slider {
                left: 0;
              }
            }

            p {
              margin: 0;
              color: #596980;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              font-style: normal;
              font-family: SF Pro Display;
            }
          }
        }
      }
    }
  }

  .create-client-button {
    text-align: center;
    padding: 30px 0 0 0;
  }
}

.banks-boxes {
  display: flex;
  cursor: pointer;
  align-items: center;

  figure {
    margin: 0;
    width: 50px;
  }

  .bank-action-icons {
    display: flex;
    align-items: center;
    padding: 0 15px 0 0;
    box-sizing: border-box;
    width: calc(100% - 50px);
    justify-content: space-between;

    p {
      margin: 0;
      width: 260px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .action-perform {
      width: 80px;

      img {
        width: 20px;
        cursor: pointer;
        margin: 0 10px;
      }
    }
  }
}

.create-client-table {
  .css-13cymwt-control {
    height: 37px;

    div[data-value] {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }

    .css-1fdsijx-ValueContainer {
      height: 37px;
    }

    .css-1dimb5e-singleValue {
      line-height: 35px;
    }
  }

  div {
    // height: 37px;
    line-height: 37px;
  }

  .css-t3ipsp-control {
    height: 37px;
    outline: none;

    &:focus {
      outline: none;
    }

    .css-1fdsijx-ValueContainer {
      height: 37px;
    }
  }

}

.tooltip-request {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  padding: 0 0 15px;

  .headeing-switch {
    margin: 0;
    padding: 0;
  }

  .tooltip-mode {
    margin: 0 0 0 5px;
  }
}

.switch-row-box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #E3E7EE;
  margin-bottom: 30px;
  padding: 0px 5px;
  display: grid;
  grid-template-columns: 0.7fr 1.9fr 1.9fr;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}

.toggle-switch h3 {
  @media (max-width: 1200px) {
    width: 450px;
    margin-left: 120px;
  }

  @media (max-width: 1040px) {
    width: 400px;
    margin-left: 90px;
  }
}

.table-row-title {
  border-bottom: none;
  font-size: 14px;
  color: #000;
}

.toggle-switch {
  padding-left: 50px;

  h3 {
    border-bottom: none;
  }

  @media (max-width: 1200px) {
    margin-left: 0px;
    padding-left: 0px;

    h3 {
      margin-left: 0px;
    }
  }
}

.keys-and-icon {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}

.note-text {
  border-bottom: 1px solid #E3E7EE;
  margin-bottom: 30px;
  font-style: italic;
  font-size: 12px;
  color: red;
}

.info-icon-wrapper {
  background: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-left: 10px;
}

.info-icon:hover {
  color: #3E85F9;
}

.form-group-option {
  width: 216px;
}

.tooltip-container-option-tab {
  width: auto;
  height: auto;
  min-width: 250px;
  max-height: auto;
  box-sizing: border-box;

  p {
    width: 100%;
    white-space: normal;
    box-sizing: border-box;
  }

  @media (max-width: 1200px) {
    width: 200px;
    max-width: 200px;
  }
}

.create-client-button {
  button {
    line-height: 1;
  }
} 


