@mixin flexCenter($direction) {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  flex-direction: $direction;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.full-width-table {
  padding: 0 20px;
}
.full-width-table table {
  width: 100% !important;
  left: 0 !important;
}

