.notification-emails-ld-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ld-header-notification-emails {
  display: flex;
  flex-direction: column;
}

.notification-emails-container {
  margin: 0;
  flex: 5 1;
  background: #f4f6fb;
  height: calc(100vh - 110px);
  padding: 0 30px 0 0;
  width: calc(100% - 290px);
  overflow-y: auto;
}

.notification-emails-table-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #ffffff;
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.notification-emails-tbody td {
  padding-left: 0.5rem;
  font-size: 14px;
}

.notification-emails-view-details-icon {
  text-align: right;
  width: 30px;
}

.notification-emails-thead {
  background-color: #f4f6fb;
  color: #8591a6;
  font-size: 14px;
}

.notification-emails-th {
  padding-left: 0.5rem;
  height: 2rem;
}

.notification-emails-table {
  table-layout: fixed;
  width: 100%;
}

.notification-emails-flex-header {
  display: flex;
  justify-content: space-between;
}

.notification-emails-details-flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-emails-date-picker-wrapper {
  display: flex;
  align-items: center;
}

.notification-emails-customer-activity-icon {
  background-color: #3e85f9;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 3px 8px;
}

.notification-emails-reset-date-button {
  background-color: #8591a6;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding: 3px 8px;
  color: white;
}

.notification-emails-text-header {
  color: #8791a4;
  font-size: 22px;
}

.notification-emails-container-template-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 11px;
  color: #8791a4;
}

.notification-emails-under-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-emails-selects-under-header {
  display: flex;
  width: 100%;
}

.notification-emails-select-org-dropdown {
  width: 15rem;
  font-family: "SF Pro Display";
  font-size: 14px;
  color: #596980;
  margin-right: 2rem;
}

.notification-emails-search-input {
  width: 15rem;
  height: 2.4rem;
  font-family: "SF Pro Display";
  font-size: 14px;
  color: #596980;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  padding-left: 0.5rem;
  padding-right: 2rem;
}

.notification-emails-search-icon{
  margin-left: -1.5rem;
  cursor: pointer;
}

.notification-emails-search-close-icon{
  margin-left: -1.5rem;
  cursor: pointer;
  height: 1rem;
}

// Table
.notification-emails-error-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-emails-email-icon{
  height: 1.5rem;
}

.status-failed {
  color: red;
}

.status-sent {
  color: green;
}

.notification-emails-hover-target .notification-emails-tooltip {
  visibility: hidden;
  position: absolute;
}

.notification-emails-hover-target:hover .notification-emails-tooltip {
  visibility: visible;
  margin-left: 1rem;
}

.notification-emails-subject {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-emails-collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.notification-emails-arrow-icon {
  color: #596980;
  font-size: 1rem;
}

.notification-emails-pagination-box {
  margin-top: auto;
  padding: 1rem 0;
}