.outer-login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/img_half_circle.svg") no-repeat scroll left 0px bottom 0px, url("../../assets/images/img_bg_fade.svg") no-repeat scroll right 0px top 0px;
}

.inner-login {
  max-width: 360px;
  width: 100%;
  background: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0px 16px 16px rgb(50 50 71 / 8%), 0px 24px 32px rgb(50 50 71 / 8%);
}

.inner-login .logo {
  text-align: center;
  margin: 0 0 22px;
  padding: 0 0 20px;
  border-bottom: 1px solid #eaeaea;
}

.logo img {
  width: 162px;
}

.inner-login h4 {
  color: #000000;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  text-transform: capitalize;
}

.forget-title {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #5B6A83;
  letter-spacing: 0.5px;
  text-align: center;
}

.forget-title span {
  color: #3E85F9;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.login-form {
  padding: 0 0 10px;
}

.login-form input {
  color: #000;
  width: 100%;
  border: none;
  height: 40px;
  display: block;
  background: #fff;
  margin: 0 0 24px;
  line-height: 40px;
  border-radius: 20px;
  position: relative;
  padding: 0 10px 0 30px;
  box-sizing: border-box;
  box-shadow: 0px 5px 16px rgb(50 50 71 / 8%), 0px 10px 32px rgb(50 50 71 / 8%) !important;
}

.login-form button {
  color: #fff;
  width: 100%;
  border: none;
  height: 40px;
  display: block;
  line-height: 40px;
  position: relative;
  text-align: center;
  border-radius: 20px;
  background: #3E85F9;
  padding: 0 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
}

.forget-link {
  font-size: 12px;
  text-align: center;
  margin: 16px 0 0 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #3E85F9;
  font-weight: 600;
}

.submit_disabled {
  cursor: not-allowed;
  pointer-events: none;
}