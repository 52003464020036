.dashboard-page-container {
  flex: 5 1;
  background-color: #f4f6fb;
  padding: 0 10px 0 0;
  overflow: auto;

  .ds-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 20px;

    .column3 {
      flex: 4.5;
    }

    .column1 {
      flex: 4.5;

      .cv-mr-right {
        position: relative;

        .ld-main-data-container {
          .ld-requests-container {
            margin-right: 0;
          }
        }

        .switch-map {
          width: auto;
          float: right;
          right: 10px;
          position: absolute;
          padding: 0;
          top: -5px;
          z-index: 1;

          .active-table {
            background: #3E85F9 !important;
            color: #fff !important;
          }

          li {
            justify-content: center;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #8591a6;
            height: 35px;
            width: 97px;
            line-height: 37px;
            transition: .4s;
            cursor: pointer;
          }

          .week-btn {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          .month-btn {
            border-left: 1px solid #e3e7ee;
            border-right: 1px solid #e3e7ee;
          }

          .year-btn {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }

      .ds-section1-container {
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        .ds-month-main {
          flex: 1;

          .ld-requests-container {
            width: 100%;
            border-radius: 10px;
            padding-right: 10px;

            .ld-request-chart {
              margin-left: 10px;
              margin-right: 10px;
            }

            .ld-request-chart-container {
              width: 100%;
              border-radius: 10px;
              color: #5b6a83 !important;
            }
          }
        }

        .ds-week-main {
          flex: 1;

          .ld-requests-container {
            width: 100%;
            border-radius: 10px;
            padding-left: 10px;
          }
        }

      }

      .ld-requests-container {
        width: 100%;
        border-radius: 10px;
        margin-right: 0;

        .ld-request-chart-container {
          width: 100%;
          background-color: white;
          padding: 0px;
          border-radius: 10px;
          color: #5b6a83 !important;
          margin-bottom: 0;
        }
      }
    }

    .column2 {
      flex: 1;

      .requests-box {
        min-height: 330px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 330px;
        height: 100%;
        box-sizing: border-box;
        padding: 0 0 0 20px;

        .each-requests-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: white;
          text-align: center;
          justify-content: center;
          margin: 0;
          border-radius: 10px;
          padding: 0 6px;
          font-size: 12px;
          font-weight: 400;

          .request-info {
            display: flex;
            flex-direction: column;
            color: #8591a6;
            margin: 0px;
            padding: 0px;

            p {
              margin: 10px 0 4px;
              font-size: 11.5px;
            }
          }
        }
      }

      .mr-top-0 {
        margin-top: 0;
      }
    }
  }

  .df-main {
    display: flex;
    margin-bottom: 25px;
    flex-direction: row;
    font-weight: 500;
    width: 100%;

    .df-left {
      flex: 4;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      color: #596980;
      display: flex;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .bottom {
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #596980;
        display: flex;
        align-items: center;
        margin: 0;

        a {
          color: #3e85f9;
          cursor: pointer;
          text-decoration: none;
        }

        .arrow {
          margin: 0 6px;
          height: 8px;
        }
      }
    }

    .df-right {
      flex: 1;

      .ld-search-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        height: 37px;
        width: auto;

        .df-form-container {
          display: flex;
          justify-content: space-between;
          height: 37px;
        }

        #df-form-container {

          .ld-form-input-field,
          #form-date-field {
            border-radius: 6px;
            border: 1px solid #e3e7ee;
            height: 36px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.03em;
            color: #5b6a83;
            padding: 0.375rem 0.75rem;
            margin-right: 10px;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            width: 190px;
            background-color: white;

            &:focus {
              outline: none !important;
              border: 0.1px solid #62a4eb;
            }
          }

          #form-date-field {
            padding: 0.75rem 0 0.75rem 0.75rem;
            width: 230px;
          }
        }

        .ld-calendar-icon {
          background-color: #3e85f9;
          margin-left: -39px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          width: 30px;
          height: 34px;
        }

      }
    }
  }

  .ds-second {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ds-second-top {
      padding-right: 20px;

      .record-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px -0.5%;

        .record-box {
          display: flex;
          justify-content: center;
          align-items: baseline;
          padding: 5px 10px 10px 10px;
          background-color: #fff;
          border-radius: 10px;
          width: 15.66%;
          margin: 0 0.5%;
          height: 70px;
          flex: none;
          font-size: 12px;
          color: #8591A6;

          .rd-box {
            text-align: center;

            .h2-font {
              font-size: 20px;
              line-height: 24px;
              text-align: center;
              margin: 6px 0 0;
              color: #596980;

            }

            .mr-bt {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .ds-second-bottom {
      .clients-container {
        flex: 5;
        background-color: #fff;
        position: relative;
        display: flex;
        text-align: center;
        flex-direction: column;
        padding: 20px;
        margin: 0 20px 20px 0;
        border-radius: 20px;

        .user-category-thread-table {
          .main-table {
            text-align: left;
            font-size: 13px;
            font-weight: 400;
            width: 100%;

            tr {

              th,
              td {
                min-width: 150px;
                text-align: center;
                padding: 0 10px;

                &:first-child {
                  text-align: left;
                  min-width: 300px;
                }
              }
            }
          }
        }

        .search-control {
          width: 300px;
          margin: 0px 0px 0px auto;
          height: 37px;
          padding: 20px;
          font-style: italic;
          font-weight: normal;
          font-size: 10px;
          color: #C6C8CC;
          line-height: 110.4%;
          background-color: #f4f6fb;
          border: none;
          display: block;
          padding: 0 42px 0 17px;
        }
      }
    }
  }
}

.ld-request-chart {
  margin: 20px 45px 30px 10px;
}

.highcharts-color-0 {
  width: 50px;
}

.activeOrgBackgraound {
  background-color: #3f85f9;
  color: #FFFFFF;
}

.data-labels-button {
  background-color: #3e85f9;
  color: #FFFFFF;
  border: none;
  font-size: 12px;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  float: right;
  margin-bottom: 20px;
  margin-top: 10px;
}