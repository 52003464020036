.light-grey-row {
  background-color: #f4f6fb;
}

.select-clients {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #e3e7ee;
  color: #5b6a83;
}

.select-clients option {
  background-color: blanchedalmond;
  cursor: pointer !important;
}

.organizations-thead-total-clients {
  background: #F4F6FB;
  color: #5B6983;
  font-weight: 400;
  border-bottom: 2px solid #F4F6FB !important;
  border-top: 1px solid #F4F6FB !important;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 150px;
}

.total-clients-table,
.total-clients-table-body {
  border-collapse: collapse;
}

table .total-clients-table-body .td-line {
  padding: 1px;
  border: none;
}

.total-clients-table thead tr {
  display: grid;
  grid-template-columns: 0.4fr 1.3fr 1.3fr 1.3fr 0.4fr;
  background: #F3F6FB;
  color: #8591a6;
  border-bottom: 1px solid #F3F6FB;
}

.total-clients-table-body tr {
  display: grid;
  grid-template-columns: 0.4fr 1.3fr 1.3fr 1.3fr 0.4fr;
  color: #8591a6;
}

.total-clients-table-new thead tr {
  display: grid;
  grid-template-columns: 0.5fr 1.7fr 2.4fr 0.5fr;
  background: #F3F6FB;
  color: #8591a6;
  border-bottom: 1px solid #F3F6FB;
}

.table-body-clients-year tbody tr {
  display: grid;
  grid-template-columns: 0.5fr 1.7fr 2.4fr 0.5fr;
  color: #8591a6;
}

.select-container-clients select {
  padding: 5px;
  border: 1px solid #e3e7ee;
  border-radius: 5px;
  color: #5B6983;
}

.table-header-clients-week {
  grid-template-columns: 0.7fr 1.5fr 1fr 1fr !important;
}