// Utilities
@import './utilities/utilities';

//GENERAL STYLING STARTS BELOW
.page-container {
  flex: 5;
  background-color: #f4f6fb;
  height: calc(100vh - 110px);
}

.sms-log-container {
  font-weight: $fw-regular;
  color: $base-dark-grey;

  h3 {
    font-size: 30px;
    line-height: 60px;
    color: $base-dark-grey;
  }
}

//STYLING FOR THE INPUT-BOXES AT THE
//TOP OF THE PAGE STARTS HERE
.sms-header {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 15px 30px 0px 10px;
  font-weight: 400;
  font-size: 12px;
  color: #5b6a83;
  align-items: end;
}

.sms-header .clear-filter-btn {
  height: 40px;
  border-radius: 4px;
}

.sms-filter-container {
  position: relative;
  display: flex;
  width: 450px;
  flex-direction: column;
  margin-right: 20px;
  overflow: hidden;
}

.select-filter-sms {
  position: relative;
  height: 40px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid lightgray;
  background-color: white;
  color: #5b6a83;
  margin-top: 2px;
}

.sms-date-box {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  color: #596980;
  font-size: 12px;
  height: 40px !important;
  padding-left: 10px;
  margin-top: 20px;
  margin-right: -5px;
  outline: none;
  width: 450px !important;
}

.calendar-icon-sms {
  background-color: $base-blue;
  margin-right: 20px;
  margin-top: 20px;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 40px !important;
  padding: 9px;
  width: 40px !important;
  font-size: 14px !important;
}

.sms-filter-button {
  height: 40px;
  width: 250px;
  background-color: $base-blue;
  border: 1px $base-blue;
  border-radius: 3px;
  color: white;
  margin-top: 20px;
}

.sms-header {
  .sms-filter-container {
    .react-select-custom {
      margin-top: 2px;

      .css-13cymwt-control {
        height: 40px;
      }

      div {
        font-size: 12px;
      }

      #react-select-2-placeholder {
        font-size: 12px;
      }
    }
  }
}

//STYLING FOR TOP 5 CLIENTS SECTION STARTS HERE
.sms-top-clients-container {
  @include flexCenter(column);
  border-radius: 10px;
  background-color: $base-white;
  padding: 20px;
  margin: 15px 30px 0px 10px;

  .sms-top-clients-chart-header {
    @include flexSpaceBetween;
    margin-bottom: 17px;
  }

  .highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }
}

//STLING FOR RECENT LOGS STARTS HERE
.sms-table-container-scroll-y {
  height: calc(100vh - 269px);
  overflow-y: auto;
}

.sms-recent-log-table-container {
  background-color: $base-white;
  margin: 15px 30px 0px 10px;
  border-radius: 10px;
  padding: 20px;
  height: calc(100vh - 168px);

  .sms-recent-log-table-header {
    @include flexSpaceBetween;
    margin-bottom: 17px;

    .search-bar-form {
      @include flexSpaceBetween;
      width: 258px;
      padding: 10px 17px;
      background-color: #f4f6fb;
      border-radius: 25px;
      border: none;
    }
  }
}

.sms-search-field {
  position: relative;
  display: flex;
}

.sms-search {
  width: 260px;
  margin: 0px 0px 0px auto;
  border-radius: 25px;
  height: 37px;
  padding: 20px;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #596980;
  line-height: 110.4%;
  background-color: #f4f6fb;
  border: none;
  display: block;
  padding: 0 42px 0 17px;
}

.sms-search-icon {
  background: #f4f6fb;
  padding-right: 15px;
  border-radius: 100px;
  padding-left: 1px;
  margin: 0px;
  margin-left: -30px;
  cursor: pointer;
}

.sms-close-icon {
  padding-right: 9px;
  padding-left: 7px;
  margin-left: -45px;
  cursor: pointer !important;
}

.sms-search::placeholder {
  font-style: italic;
  font-size: 12px;
}

.sms-search:focus {
  background-color: #f4f6fb;
  box-shadow: none;
  outline: none;
  border-color: #f4f6fb !important;
}

.sms-recent-log-table,
#sms-recent-log-table {
  color: #212529;
  width: 100%;

  .th-light {
    background-color: #f4f6fb;
    height: 48px;
  }

  .sms-th,
  .sms-td {
    padding: 0.75rem;
    font-size: $fs-body;
    line-height: 1.2;
    font-weight: $fw-regular;
    color: $base-dark-grey;
  }

  .sms-th {
    padding-top: 15px;
    padding-bottom: 16px;
    padding-left: 10px;
  }

  .td {
    border-top: 1px solid #dee2e6;
  }
}

//STYLING FOR RECENT LOG PAGINATION
.sms-pagination-box {
  text-align: center;
  padding: 2rem 0 0 0;

  li {
    font-size: 14px !important;
  }
}