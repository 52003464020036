#notfound {
	position: relative;
	height: 100vh;
	.notfound {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}
.notfound {
	max-width: 520px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	.notfound-404 {
		position: relative;
		height: 200px;
		margin: 0px auto 20px;
		z-index: -1;
		h1 {
			font-family: 'Montserrat', sans-serif;
			font-size: 236px;
			font-weight: 200;
			margin: 0px;
			color: #211b19;
			text-transform: uppercase;
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
		h2 {
			font-family: 'Montserrat', sans-serif;
			font-size: 28px;
			font-weight: 400;
			text-transform: uppercase;
			color: #211b19;
			background: #fff;
			padding: 10px 5px;
			margin: auto;
			display: inline-block;
			position: absolute;
			bottom: 0px;
			left: 0;
			right: 0;
		}
	}
	a {
		font-family: 'Montserrat', sans-serif;
		display: inline-block;
		font-weight: 700;
		text-decoration: none;
		color: #fff;
		text-transform: uppercase;
		padding: 13px 23px;
		background: #ff6300;
		font-size: 18px;
		-webkit-transition: 0.2s all;
		transition: 0.2s all;
		&:hover {
			color: #ff6300;
			background: #211b19;
		}
	}
}