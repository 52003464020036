.add-download-report {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex: 1 75%;
}

.add-download-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-download-report span {
  margin: 5px 0 0 0;
  cursor: pointer;
  display: flex;
}

.add-download-box .add-download-details .form-group {
  margin: 0 10px;
  width: 39%;
}

.add-download-box .add-download-details .form-group input {
  margin: 0 0 15px 0;
  background: #FFFFFF;
  padding: 10px;
  width: 100%;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.add-download-box .add-download-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  justify-content: flex-end;
}