@mixin flexCenter($direction) {
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: $direction;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

