.switch-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-date {
  display: flex;

  .input-group-addon {
    background: #e3e7ee;
  }

  .search-input input {
    width: 370px;
  }
}

.switch-map {
  margin-top: 16px;
  margin-left: 0px;
  width: 194px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e7ee;
  box-sizing: border-box;
  border-radius: 6px;
  box-sizing: border-box;

  li {
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #8591a6;
    height: 35px;
    width: 97px;
    line-height: 37px;
    transition: 0.4s;
    cursor: pointer;
    list-style: none;

    &:hover {
      background: #3e85f9 !important;
      color: #fff !important;
    }
  }
}

.active-table {
  background: #3e85f9 !important;
  color: #fff !important;
}

.icon-map,
.icon-month,
.week-btn {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.icon-table,
.icon-year,
.year-btn {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.input-sets {
  .k-datepicker {
    width: 100%;

    span {
      z-index: 9 !important;
    }

    #form-date-field {
      height: 37px;
      width: 223px !important;
    }
  }

  input {
    width: 224px;
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #8591a6;
    padding: 0 47px 0 10px;
    background-color: #ffffff !important;
    border: 1px solid #e3e7ee;
    box-sizing: border-box;
    border-radius: 6px !important;
    height: 37px;
    line-height: 37px;
    z-index: 1;
  }

  .input-group-addon {
    background-color: #3e85f9;
    border: 1px solid #e3e7ee;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 1px;
    z-index: 9;
    height: 37px;
    width: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .k-datepicker {
    width: 100%;
  }
}

.tab-date .input-group-addon {
  background: #e3e7ee;
  z-index: 1 !important;
}

.tab-date {
  display: flex;
  align-items: center;

  .switch-map {
    margin-right: 10px;

    li:hover,
    .active-table {
      background: #3e85f9 !important;
      color: #fff !important;
    }
  }
}

.search-input {
  position: relative;
  margin-left: 0px;

  .search-icon {
    position: absolute;
    right: 15px !important;
    top: 12px;
    height: 13px;
    padding: 0 !important;
    height: 13px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .close {
    height: 15px;
  }

  .search-input {
    background: #f4f6fb;
    border-radius: 25px;
    max-width: 258px !important;
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 110.4%;
    display: flex;
    align-items: center;
    color: #c6c8cc;
    height: 37px;
    line-height: 37px;
    border: none;
    display: block;
    padding: 0 42px 0 17px;
    outline: none;
  }
}

.table-content {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  overflow-x: auto;
  padding-left: 0px;

  table {

    thead,
    tfoot {
      background: #f4f6fb;
      border-radius: 4px;
    }

    thead tr td,
    tbody tr td {

      &:nth-child(1) {
        min-width: 30px;
        max-width: 30px;
      }

      &:nth-child(2) {
        min-width: 115px;
        max-width: 115px;
      }

      &:nth-child(3) {
        min-width: 55px;
        max-width: 55px;
      }

      &:nth-child(4) {
        min-width: 76px;
        max-width: 76px;
      }

      &:nth-child(5) {
        min-width: 70px;
        max-width: 70px;
      }

      &:nth-child(6) {
        min-width: 73px;
        max-width: 73px;
      }

      &:nth-child(7) {
        min-width: 88px;
        max-width: 88px;
      }

      &:nth-child(8) {
        min-width: 60px;
        max-width: 60px;
      }

      &:nth-child(9) {
        min-width: 50px;
        max-width: 50px;
      }

      &:nth-child(10) {
        min-width: 59px;
        max-width: 59px;
      }

    }

    .MuiSvgIcon-root {
      cursor: pointer;
    }

    thead tr td a {
      margin: 0 0 0 10px;
    }

    thead tr td,
    tfoot tr td {
      color: #8591a6;
      font-weight: 500;
      padding: 7px !important;
    }

    .MuiTableCell-root.invoicing-sub-acc-table {
      padding: 0 !important;
    }

    tbody tr td {
      color: #596980;
      padding: 7px;
      text-transform: capitalize;
      border-bottom: 1px solid #f4f6fb;

      .MuiCollapse-wrapper {
        height: auto;

        .mui-collapse-container {
          margin: 0;
          padding: 0;
          height: auto;
          border: none !important;
          transform: translateY(0px);

          .mui-section-container {
            top: 0;

            .mui-bg {
              padding: 0;

              td {
                &:first-child {
                  padding-left: 15px;
                }
              }
            }
          }
        }
      }



      a {
        color: #3e85f9;
        padding: 0 5px;

        &:hover {
          color: #3e85f9;
          padding: 0 5px;
        }
      }

      span {
        font-size: 12px;
      }
    }
  }
}

span.sub-account-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
}

span.no-sub-account-container {
  padding-left: 10%;
  display: flex;
  // justify-content: space-between;
}

.bg-white-invoices {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;

  .table-content {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    overflow-x: auto;

    table {
      width: 100%;
      margin: 0;
      border-collapse: collapse;
      background-color: transparent;

      thead {
        background: #f4f6fb;
        border-radius: 4px;

        tr td {
          font-weight: 500;
          color: #8591a6;
          padding: 10px 20px;
        }
      }

      tfoot tr td {
        font-weight: 500;
        color: #8591a6;
        padding: 10px 20px;
      }
    }
  }
}

.basic-head03 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #596980;
}

.basic-head01 {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #596980;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .basic-flex-start {
    align-items: flex-start;
    margin: 0 0 14px;
  }
}

.invoices-thead {
  background: #f4f6fb;
  color: #8591a6;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border-bottom: 2px solid #f4f6fb !important;
  border-top: 1px solid #f4f6fb !important;
  border-radius: 5rem !important;
}

.invoices-tbody {
  color: #596980;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border-bottom: 2px solid #f4f6fb !important;
  border-top: 1px solid #f4f6fb !important;
  border-radius: 5rem !important;
}

.invoices-thead-scroll-y {
  overflow-y: auto;
  height: calc(100vh - 285px);
}

.switch-search {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .td-none {
    text-decoration: none;
  }

  .custom-button {
    background: #ffffff;
    border: 1px solid #3e85f9;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    height: 37px;
    line-height: 35px;
    padding: 0 15px;
    margin: 0 0 0 10px;
    color: #3e85f9;
    transition: all 0.4s;
  }

  .tab-date {
    display: flex;
    align-items: center;

    .custom-button {
      background: #3e85f9;
      border: 1px solid #3e85f9;
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;
      height: 37px;
      line-height: 35px;
      padding: 0 15px;
      margin: 0 0 0 10px;
      color: #ffffff;
      transition: all 0.4s;
    }

    .custom-button:hover {
      background: #ffffff;
      border: 1px solid #3e85f9;
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;
      height: 37px;
      line-height: 35px;
      padding: 0 15px;
      margin: 0 0 0 10px;
      color: #3e85f9;
      transition: all 0.4s;
    }

    .search-button {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      background: #3e85f9;
      border: 1px solid #3e85f9;
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;
      height: 37px;
      line-height: 35px;
      // padding: 0 15px;
      margin: 0 0 0 10px;
      color: #ffffff;
      transition: all 0.4s;
      width: 70px;
    }

    .basic-head02 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #596980;
    }
  }

  .search-date {
    display: flex;
    background: #ffffff;
    border: 1px solid #e3e7ee;
    box-sizing: border-box;
    border-radius: 6px;

    .form-group {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .form-group:first-child {
      margin-right: 10px;
    }

    .form-group:nth-child(2) {
      .form-control {
        border-left: 1px solid #e3e7ee;
      }
    }

    .form-control {
      border: none;
      padding: 0 10px;
      width: 100px;
      height: 22px;
      line-height: 22px;
      box-shadow: none;
      border-radius: 0;
      -webkit-appearance: auto;
      color: #596980;
    }

    button {
      overflow: visible;
      cursor: pointer;
      width: 60px;
      padding: 0;
      color: #fff;
      background: #3e85f9;
      margin: 0 0 0 4px;

      .custom-button {
        background: #ffffff;
        border: 1px solid #3e85f9;
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
        height: 37px;
        line-height: 35px;
        padding: 0 15px;
        margin: 0 0 0 10px;
        color: #3e85f9;
        transition: all 0.4s;
      }
    }
  }
}

#invoice-calendar-icon {
  background-color: #3e85f9;
  height: 100%;
  border-top: 1px solid #3e85f9;
}

.table-content {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  overflow-x: auto;

  .mr-top {
    margin-top: 0 !important;
  }

  .text-right {
    text-align: end;
  }

  .invoice-details-table {
    margin: 30px 0 0;

    table {
      border: 1px solid #596980;
      width: 100%;
      margin: 0;
      border-collapse: collapse;

      td {
        border: 1px solid #596980 !important;
      }

      tfoot,
      thead {
        background: #596980;

        .bg-white-td {
          background: #fff !important;
          color: #596980 !important;

          td {
            color: #596980 !important;
            border: #fff !important;
          }
        }

        tr td {
          font-weight: 500;
          color: #fff;
          padding: 10px 20px;
        }

      }
    }
  }
}

span.country-dots {
  position: relative;
  top: 5px;
}


.more-details-btn {
  margin: 20px 0 40px;
  text-align: right;

  .custom-button {
    background: #fff;
    border: 1px solid #3e85f9;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    height: 37px;
    line-height: 35px;
    padding: 0 15px;
    margin: 0 0 0 10px;
    color: #3e85f9;
    transition: all 0.4s;
    display: inline-block;
    text-decoration: none;
  }
}

.custom-button {
  background: #ffffff;
  border: 1px solid #3e85f9;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  height: 37px;
  line-height: 35px;
  padding: 0 15px;
  margin: 0 0 0 10px;
  color: #3e85f9;
  transition: all 0.4s;
}

.invoice-excel-icon {
  height: 35px;
  margin-left: 5px;
}

.invoice-excel-icon:hover {
  cursor: pointer;
}

// PDF STyle
.doc_section {
  margin: 10px;
  padding: 10px;
  flex-grow: 1;

  .header1 {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #596980;
    font-style: normal;
  }

  .header2 {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #596980;
    font-style: normal;
  }
}

.hidden {
  display: none;
}

// new PDF
.pr-main {
  margin: 20px;
  font-family: 'SF Pro Display';

  .pr-heading1 {
    text-align: center;
    font-size: 15px;
    color: #596980;
    font-style: normal;
  }

  .pr-heading2 {
    text-align: center;
    font-size: 12px;
    color: #596980;
    font-style: normal;
  }

  .pr-heading3 {
    text-align: left;
    font-size: 18px;
    color: #596980;
    font-style: normal;
  }

  .pr-heading4 {
    text-align: right;
    font-size: 12px;
    color: #596980;
    font-style: normal;
  }

  .pr-margin {
    margin-top: 30px;
    text-align: center;
  }

  .table-content {
    .custome-thead {
      text-align: left;

      td {
        border: 1px solid #ffffff;
      }
    }
  }

  .wd-100 {
    width: 100%;
  }
}

.invoice-tooltip {
  display: none;
  position: absolute;
  top: 20px;
  z-index: 10;
  background-color: #596980;
  color: white;
  padding: 5%;
  border-radius: 5px;
  left: 0.1px;
  min-width: 90px;
  text-align: center;
}

.hover-text:hover .invoice-tooltip {
  display: inherit;
}

.invoice-tooltip-header {
  display: none;
  position: absolute;
  top: 20px;
  z-index: 10;
  background-color: #596980;
  color: white;
  padding: 5%;
  border-radius: 5px;
  left: 0.1px;
  min-width: 180px;
  text-align: center;
}

.header-tooltip-hover:hover .invoice-tooltip-header {
  display: inherit;
}

span.invoice-tooltip-wrapper {
  position: relative;
}

// @media all {
//   .page-break {
//     display: none;
//   }
// }

// @media print {
//   html, body {
//     height: initial !important;
//     overflow: initial !important;
//     -webkit-print-color-adjust: exact;
//   }
// }

// @media print {
//   .page-break {
//     margin-top: 1rem;
//     display: block;
//     page-break-before: auto;
//   }
// }

// @page {
//   size: auto;
//   margin-top: 10mm;
//   margin-bottom: 10mm;
// }
.sub_accountbg {
  background-color: #f4f6fb;
}


// .table-td-width table tr td {
//   // min-width: 140px;
// }

.table-td-width table tr td:nth-child(1) {
  min-width: 120px;
}

.table-td-width table tr td:nth-child(2) {
  min-width: 120px;
}

.table-td-width table tr td:nth-child(3) {
  min-width: 160px;
}

.table-td-width table tr td:nth-child(4) {
  min-width: 120px;
}

.table-td-width table tr td:nth-child(5) {
  min-width: 140px;
}

.table-td-width table tr td:nth-child(6) {
  min-width: 110px;
}

.invoicing-flag {
  margin: 0 2px;
}


@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  footer {
    position: fixed;
    bottom: 0;
  }

  header {
    position: fixed;
    top: 0;
  }

  .pagebreak {
    page-break-before: always;
  }
}

@page {
  size: A4;
  margin: 11mm 0mm 17mm 0mm;
}

@media screen and (min-width: 1800px) {
  span.sub-account-container {
    padding-left: 20%;
    padding-right: 10%
  }

  span.no-sub-account-container {
    padding-left: 20%;
  }
}

@media screen and (min-width: 2000px) {
  span.sub-account-container {
    padding-left: 25%;
    padding-right: 15%
  }

  span.no-sub-account-container {
    padding-left: 25%;
  }
}

.invoicing-inactive-text{
  color: red;
  font-family: 'SF Pro Display';
  font-weight: bold;
}