.list-loader {
  border-top: 4px solid #26282a !important;
  border-right: 4px solid #26282a !important;
  border-left: 4px solid #26282a !important;
  border-bottom: 4px solid transparent !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  animation: spin 2s linear infinite !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  margin-left: calc(50% - 20px)!important;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}