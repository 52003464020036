.detail-two-box .details-left.details-full-box .form-group {
  align-items: center;
  justify-content: space-between;
}

.detail-two-box .details-left.details-full-box .notification-setting {
  display: flex;
  margin: 0 0 30px;
}

.details-left .form-group h3 {
  color: #596980;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}

.details-left .form-group input {
  background: #FFFFFF;
  height: 37px;
  line-height: 37px;
  padding: 0 10px;
  width: 340px;
  display: block;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}