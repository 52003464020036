//UTILITIES
@import './utilities/utilities';

//STYLING FOR THE HEADER STARTS
//HERE
.reports-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  max-height: 37px;

  h3 {
    margin: 0;
    text-wrap: nowrap;
  }

  .push-right {
    margin-left: auto;
  }

  .reports-select {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    color: #a5a6a7;
    font-size: 12px;
    height: 37px !important;
    padding: 0 10px;
    margin: 0 6px;
    width: 11rem;
    outline: none;
    appearance: none;
    background-image: url('../../../assets/images/icon_down.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 18px;
  }

  .reports-search-field {
    position: relative;
    display: flex;

    img {
      position: absolute;
      right: 28px;
      top: 50%;
      transform: translate(50%, -50%);
      cursor: pointer;
    }
  }

  .reports-search {
    width: clamp(160px, 16rem, 260px);
    border-radius: 25px;
    height: 37px;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    color: #596980;
    margin: 0 6px;
    background-color: #ffffff;
    border: none;
    padding: 0 42px 0 17px;
  }

  .reports-date-box {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    color: #596980;
    font-size: 12px;
    height: 37px !important;
    padding-left: 10px;
    margin: 0 -6px 0 6px;
    outline: none;
  }

  .reports-calendar-icon {
    background-color: $base-blue;
    margin-right: 6px;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 37px !important;
    padding: 8px;
    font-size: 14px !important;
  }

  .reports-download-icon {
    height: 37px;
    margin: 0 6px;

    &:hover {
      cursor: pointer;
      filter: brightness(1.15) drop-shadow(0px 0px 2px rgba(100, 211, 255, 0.39));
    }
  }
}

//STYLING FOR TABLE STARTS HERE
.reports-table-container {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;

  .reports-th {
    font-size: $fs-body;
    font-weight: $fw-regular;
    background-color: #f4f6fb;

    th {
      color: #596980;
      font-weight: normal;
      width: 20%;
      border-top: none;
      border-bottom: none;
      padding: 12px 16px;
    }
  }

  .reports-tr {

    animation: fadeIn 0.3s ease-out 0.1s 1 forwards;
    opacity: 0;
    max-width: 100%;

    th,
    td {
      padding: 16px;
      font-size: $fs-body;
      font-weight: 400;
      color: $base-dark-grey;
      min-width: 180px;
      width: 15%;
      max-width: 210px;

      &:first-child {
        width: 12%;
        min-width: 100px;
        max-width: 140px;
      }

      &:nth-last-child(2) {
        width: 100%;
        max-width: 500px;
        min-width: 210px;
      }

      &:last-child {
        justify-self: flex-end;
        align-self: flex-end;
        width: 100px;
        min-width: 120px;
        text-align: center;
      }


      .reports-tr-text {
        position: relative;
        padding: 6px;
        margin: -6px;
        display: inline-block;

        .reports-tr-underline {
          background-color: rgb(214, 214, 214);
          position: absolute;
          width: 0;
          height: 2px;
          left: 0;
          bottom: 4px;
        }

        .sort-icon {
          opacity: 0;
          margin-left: 5px;
          height: 10px;
          width: 10px;
        }

        &:hover {
          cursor: pointer;

          .reports-tr-underline {
            width: 100%;
            transition: width 0.3s ease-out;
          }

          .sort-icon {
            opacity: 1;
          }
        }
      }
    }
  }
}

.audit-report-table-skeleton {
  width: 100%;
  height: 400px;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}


.audit-report-table-skeleton-overlay {
  width: 100%;
  height: 100%;
  min-height: 120px;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  backdrop-filter: blur(1px);
  align-items: center;
  background-color: rgba($color: #FFF, $alpha: 0.6);
}

//STYLING FOR PAGINATION BOX
.reports-pagination-box {
  text-align: center;
  padding: 2rem 0 0 0;

  li {
    font-size: 14px !important;
  }
}

.audit-report-tbody {
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}