.ld-search-container {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.page-container.live-data-container .ld-container {
  border-radius: 10px !important;
  overflow: hidden;
}

.page-container.live-data-container .ld-container .highcharts-container {
  border-radius: 10px !important;
  overflow: hidden;
}