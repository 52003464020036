//#region organizationeventlog main css

.ld-header-organizationeventlog{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.inner-page-container-organizationeventlog {
  flex: 5;
  height: calc(100vh - 110px);
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;
}

.controls-container-organizationeventlog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 0;

  .dropdowns-container-organizationeventlog {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    @media (max-width: 1366px) {
      align-items: flex-start;
    }
  }
}

.ld-form-input-field.disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}

.react-select-custom-org {
  width: 10rem;
  margin-left: 1rem;
  z-index: 1;
}

.organizationeventlog-ld-search-container .organizationeventlog-custom-select .select-width-control .ld-form-input-field {
  width: 10.4rem;

  @media (min-width: 1740px) {
    width: 12.9rem;
  }
}

.custom-select-container {
  position: relative;

  .organizationeventlog-custom-select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ld-form-input-field {
      width: 100%; 
      color: #000; 
      padding: 8px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      &:focus {
        outline: none; 
        border-color: #80bdff; 
      }
    }

    .dropdown-icon {
      position: absolute;
      right:15px;
      cursor: pointer; 
      background-image: url('/assets/images/icon_dropdown_arrow.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height:4px;
      width: 10px;
      transform: translateY(-50%);
    }

    .open .dropdown-icon {
      transform: translateY(-50%) rotate(180deg); 
    }
  }

  .options-list {
    list-style-type: none;
    position: absolute;
    top: 100%; 
    left: 0;
    z-index: 10;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    border-radius: 5px;
    margin-top: 5px; 
    max-height: 200px;
    font-size: small;
    overflow-y: auto;
    padding: 0; 

    .ld-option {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
      
      &:hover, &.selected {
        background-color: #007bff; 
        color: #fff; 
      }
    }
  }
}

.certificateValue {
  color: #007bff;
  cursor: pointer;
}

.pagination-bar-eventlog {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 10%;
  margin: 0 auto;
  margin-bottom: 20px;
}
//#endregion

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0; 
  visibility: hidden; 
  transition: opacity 0.5s, visibility 0s linear 0.5s; 
}

.modalBackdrop.show {
  opacity: 1; 
  visibility: visible; 
  transition: opacity 0.5s;
}

.headerCertLog {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.certificateModal {
  position: relative;
  width: 80%;
  max-width: 600px;
  margin: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 1001;
  overflow-y: auto; 
  max-height: 80%; 
}

.certificateModalCloseBtn {
  position: relative;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  justify-content: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.certificateModalCloseBtn:hover {
  background-color: #0056b3;
}

.certificatePaper {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  margin-bottom: 20px; 
}
.certificatePaper {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  margin-bottom: 20px; 
}

.loader {
  border: 4px solid rgba(0,0,0,.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #007bff;
  animation: spin 1s ease infinite;
  margin: "auto"
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-select-wrapper.disabled {
  position: relative;
}

.custom-select-wrapper.disabled .custom-tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #555;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 0.9em;
}

.custom-select-wrapper.disabled:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

.eventlogs-tbody {
  height: 75%;
  margin-bottom: 20px; 
}

@media (min-width: 1468px) {
  .eventlogs-tbody {
    height: 70%;
  }
}
