.org-head-excel-container {
  display: flex;
  align-items: end;
}

.org-no-activity-excel-icon {
  margin-left: 5px;
  cursor: pointer;
  height: 35px;
}

.organizations-tbody td:last-child,
.organizations-thead td:last-child {
  max-width: 125px;
}

.organizations-tbody td,
.organizations-thead td {
  min-width: 135px;
}

@media screen and (min-width: 1520px) {

  .organizations-tbody td:last-child,
  .organizations-thead td:last-child {
    max-width: 100px;
  }
}

@media screen and (min-width: 1280px) {

  .organizations-tbody td,
  .organizations-thead td {
    min-width: 70px;
  }
}