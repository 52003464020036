 .broker-flow-chart {
  *{
    transition: none !important;
  }

 }
 
 .broker-flow-chart .highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-size: 12px;
    user-select: none;
    touch-action: manipulation;
    outline: none;
  }
  