.organizations-providers-thead td {
  min-width: 150px;
}

.organizations-providers-thead td:last-child {
  min-width: 110px;
}

.op_img {
  cursor: pointer;
  display: flex;
  padding: 0;
}

.op_img:hover {
  padding: 0;
}