.documentation-container {
  margin-top: 20px;
  margin-right: 0px;
}

.it-dropdown-menu.show {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 43px, 0px) !important;
}

div.btn-container.btn-api-list {
  display: flex;
}

.active-true {
  color: #00956a;
  background-color: #e1fae3;
  padding: 5% 0;
  border-radius: 25px;
  width: 50px;
  display: block;
  text-align: center;
}

.active-false {
  color: #ff647c;
  background-color: #ffe6e6;
  padding: 5% 0;
  border-radius: 25px;
  width: 50px;
  display: block;
  text-align: center;
}



.confirm-btn-container {
  .delete-api-btn {
    color: red;
    background: white;
    border: 1px solid red;
    font-size: 14px;
    width: 120px;
    border-radius: 6px;
    height: 27px;
    margin: 5px;
    cursor: pointer;
  }

  .delete-api-btn:hover {
    background-color: red;
    color: white;
  }

}

.api-edit-icon {
  padding: 0 2px;
  cursor: pointer;
}

.api-clone-icon {
  cursor: pointer;
}

.api-switch-container {
  margin-top: 0;
  position: relative;
  top: 30px;
  display: flex;
  justify-content: flex-end;

  .enable-switch-api {
    font-family: SF Pro Display;
    color: #596980;
    font-size: 15px;

    .enable-span {
      position: relative;
      left: 10px;
    }

  }
}