.email-sms-ld-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;

  }
  
  .ld-header-email-sms {
      display: flex;
      flex-direction: column;
    }
  
    .email-sms-container {
      margin: 0;
      flex: 5 1;
      background: #f4f6fb;
      height: calc(100vh - 110px);
      padding: 0 30px 0 0;
      width: calc(100% - 290px);
      overflow-y: auto;
    }
  
    .email-sms-table-top {
      margin-top: 1rem;
      padding: 1rem;
      background-color: #FFFFFF;
      height: 93%;
      overflow-y: auto;
    }

.email-sms-select{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.email-sms-text-header{
  color: #8791A4;
  font-size: 22px;
}

.email-sms-selects-container{
  display: flex;
  justify-content: space-between;
}

.email-sms-switch-map{
    margin-top: 16px;
    margin-left: 0px;
    width: 194px;
    display: flex;
    border: 1px solid #e3e7ee;
    box-sizing: border-box;
    border-radius: 6px;
    box-sizing: border-box;
  
    li {
      font-size: 12px;
      text-align: center;
      color: #8591a6;
      height: 35px;
      width: 97px;
      line-height: 37px;
      cursor: pointer;
      list-style: none;

      &:hover {
        background: #3e85f9 !important;
        color: #fff !important;
      }
    }
  }

.email-sms-switch-map li {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

#email-sms-switch-id {
    border-right: 1px solid #e3e7ee;
    margin-left: -35px;
}

.email-sms-switch-map .active {
    background-color: #3e85f9;
    color: white;
}

.email-sms-container-template-select{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 11px;
  color: #8791A4;
}

.email-sms-template-select{
  width: 16rem;
  font-family: "SF Pro Display";
  font-size: 12px;
  color: #596980;
}

.email-sms-select-org-dropdown {
  width: 15rem;
  margin-right: 1rem;
  font-family: "SF Pro Display";
  font-size: 14px;
  color: #596980;
}
//Template Editor
.email-sms-template-custom-form {
margin: auto;
max-width: 60%;
margin-top: 2rem;
}

.email-sms-template-input {
  width: 100%;
  margin-bottom: 1rem;

  .form-control {
    width: 100%;
  }
}

.email-sms-editor {
  width: 100%;
  margin-bottom: 3rem;
  height: 25rem;
}

.form-control {
  height: 38px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccd0d5;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(76, 89, 104, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.email-sms-template-btn{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}

@mixin email-sms-button-style {
  background-color: white;
  color: #3e85f9;
  border: 1px solid #3e85f9;
  height: 2.2rem;
  border-radius: 3px;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 14px;
  margin-bottom: 5rem;
  cursor: pointer;

  &.active, &:hover {
    background-color: #3e85f9;
    border-color: #3e85f9;
    color: white;
  }
}

.email-sms-button {
  @include email-sms-button-style;
  width: 10rem;
}

.email-sms-button-preview {
  @include email-sms-button-style;

  &:disabled {
    cursor: not-allowed;
    background-color: #f2f2f2;
    color: #ccc;
    border-color: #ccc;
    &:hover {
      background-color: #f2f2f2;
      color: #ccc;
      border-color: #ccc; 
    }
  }
  width: 6rem;
  margin-right: 1rem;
}

.email-sms-button-create {
  background-color: #3e85f9;
  border: 1px solid #3e85f9;
  color: white;
  height: 2.2rem;
  width: 5rem;
  border-radius: 3px;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 14px;
  cursor: pointer;

  &.active, &:hover {
    background-color: #3e85f9;
    border-color: #3e85f9;
    color: white;
  }
}

.ql-toolbar {
  border-radius: 5px; 
}

//PreviewButton
.preview-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1001;
  width: 23rem;
  border: 2px solid black;
  text-align: center;
}

.preview-modal-actions {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 3rem;
}

@mixin preview-viewer-style {
  background-color: white;
  color: #3e85f9;
  border: 1px solid #3e85f9;
  width: 6rem;
  height: 2.2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  font-size: 14px;
}

.preview-viewer-no {
  @include preview-viewer-style;
}

.preview-viewer-yes {
  @include preview-viewer-style;
  background-color: #3e85f9;
  color: white;
}

//View Default Template
.view-default-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.view-default-modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 4px;
  border: 2px solid black;
}

.view-default-modal-close {
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.7rem;
  color: #8591a6;
}

.view-default-modal-actions {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 0.5rem;
}

.view-default-load{
  @include preview-viewer-style;
  background-color: #3e85f9;
  color: white;
  width: 11rem;
}

.view-default-close {
  @include preview-viewer-style;
}

.view-default-modal-content {
  max-height: 30rem;
  overflow-y: auto;
}

.view-default-modal-content-sms{
  color: #333333;
  font-size: 14px;
  font-family: 'SF Pro Display';
}

.email-sms-p {
  margin-top: 0.5rem !important;
  font-family: 'SF Pro Display' !important;
  font-size: 16px !important;
}

.view-default-modal-h2 {
  color: #596980;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1rem;
}

//Disable select,preview
.sms-email-select-wrapper {
  position: relative;

  select:disabled {
    background-color: #f2f2f2;
    color: #ccc;
  }
}

.sms-email-select-wrapper.disabled .sms-email-tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #555;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 0.9em;
}

.sms-email-select-wrapper.disabled:hover .sms-email-tooltip {
  visibility: visible;
  opacity: 1;
}


.preview-button-wrapper {
  position: relative;
  display: inline-block;
}

.preview-button-tooltip {
  visibility: hidden;
  width: 9.5rem;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.preview-button-wrapper.disabled:hover .preview-button-tooltip {
  visibility: visible;
  opacity: 1;
}

.preview-modal-h5{
  color: black; 
  font-weight: bold; 
  font-size: 1.4rem; 
  margin-bottom: 1rem;
}

//Sms instruction
.email-sms-editor-container {
  display: flex;
  align-items: flex-start;
}

.sms-instructions {
  width: 20rem; 
  font-size: 0.9em;
  color: black; 
  background-color: #f4f6fb;
  padding: 1rem;
  border-radius: 6px;
  font-size: 12px;
  margin-left: 0.5rem;
}

.email-sms-textarea{
  width: 35rem;
  height: 20rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 12px;
}

.org-select-endpoint-dropdown {
  width: 13rem;
  font-family: "SF Pro Display";
  font-size: 13px;
}