.aside {
  width: 250px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  overflow-y: hidden;
  border-radius: 15px;
  background: #596980;
  transition: all 0.3s;
  box-sizing: border-box;
  height: calc(100vh - 106px);
}

.section-left-side .sidebar-ul {
  margin: 0;
  padding: 15px;
  overflow-x: hidden;
  height: calc(100vh - 105px);
  overflow-y: auto;
}

.section-left-side .sidebar-ul li {
  list-style: none;
  margin: 0 0 10px 0;
  border-radius: 6px;
}

.section-left-side .sidebar-ul li.sidebar-submenu {
  margin: 0 -15px 10px;
}

.section-left-side .sidebar-ul li a {
  display: block;
  border-radius: 6px;

  &:hover {
    color: #FFFFFF;
  }
}

.section-left-side .sidebar-ul li:last-child {
  margin: 0;
}


.aside-link:hover,
.active-menu {
  color: white;
  background-color: #3E85F9;
}

.logoutdiv {
  color: #C3CBD9;
}

.logoutdiv:hover {
  // background: #3E85F9 !important;
  color: white !important;
  border-radius: 6px;
}

.imgIconSidebar {
  padding-left: 1px;
  margin-left: 10px;
}



.side-bar-link {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  text-transform: capitalize;
  background: transparent;
  border-radius: 6px;
  display: inline-block;
  text-decoration: none;
  margin: 0 0 0 0px !important;
}

.aside-link {
  color: #C3CBD9;
}

.sidebar {
  display: flex;
  color: #C3CBD9;
  position: relative;

  .side-bar-arrow-container {
    position: absolute;
    right: 0;
    top: 3%;
  }

  .arrow-up {
    transform: rotate(-180deg);
  }


  .dropdown-arrow {
    filter: brightness(0) invert(1);
  }
}

.sidebar-submenu {
  background: #485568;
  color: #C3CBD9;
  margin: 0 -15px 10px;

  ul.submenu-ul {
    padding: 4px 10px 4px 47px;
    width: 100%;

    li:hover {
      color: white;
      background-color: #3E85F9;
    }

    li {
      width: 90%;
      padding: 0;
      display: flex;
      padding: 6px;
      margin: 5px 0 !important;

      a {
        height: 100%;
        display: block;
        width: 100%;
      }
    }

    li.active-menu {
      color: white;
      background-color: #3E85F9;
    }
  }
}