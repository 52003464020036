.bank-status-error-modal-backdrop {
  background-color: rgba($color: #000000, $alpha: 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;


}

.bank-status-error-modal-container {
  background-color: rgb(240, 240, 240);
  height: calc(100% - 220px);
  width: calc(100% - 360px);
  position: fixed;
  border: 2px solid rgb(190, 190, 190);
  border-radius: 24px;
  box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  z-index: 51;

  .bank-status-error-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;

    .bank-status-error-modal-close-button {
      background: none;
      border-style: none;
      padding: 6px;
      width: 48px;
      height: 48px;
      font-size: 25px;
      color: rgba(90, 90, 90, 0.6);
      filter: brightness(1);
      scale: 1;
      font-weight: 600;

      &:hover {
        filter: brightness(0.7);
        font-weight: 900;
        scale: 1.1;
      }
    }
  }

  .bank-status-error-modal-main-text {
    margin: 24px;
    width: calc(100% - 48px);
  }

  .bank-status-error-modal-error-list {
    width: 100%;
    height: calc(100% - 48px);

    overflow: scroll;

    .bank-status-error-modal-error-list-container {
      background-color: rgba($color: #ffdd8f, $alpha: 0.3);
      border: 1px solid rgba($color: #bc9b3e, $alpha: 0.6);
      margin: 0 4px -1px 4px;

      .bank-status-error-modal-error-list-container-header {
        display: flex;
        justify-content: space-between;
        margin: 12px;
        font-size: 18px;

        .bank-status-error-modal-error-list-container-header-error-type {}

        .bank-status-error-modal-error-list-container-header-id {}
      }

      .bank-status-error-modal-error-list-container-expanded {
        font-size: 14px;
        margin: 16px;

        &>p {
          margin: 6px;
        }
      }
    }
  }
}