@mixin fontStyles {
  font-family: "SF Pro Display";
  font-weight: 500;
  color: #5C697E;
}

.data-grid {
  width: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .customers-table-name {
    margin: 20px 0px 5px 0px;
    font-size: 14px;
    @include fontStyles;
    line-height: 16px;
    color: #5C697E;
    height: 15px;
  }

  .table-wrapper-customers {
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto;
    border: 1px solid #4B5566;
    width: 100%;
  }

  .id-scroll-container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .customers-data-table .id-scroll-column {
    max-width: 50px;
    overflow: hidden;
  }

  .customers-data-table .id-scroll-column .scroll-content {
    white-space: nowrap;
    overflow-x: auto;
    display: inline-block;
    max-width: 100%;
    overflow-y: hidden;
    cursor: pointer;
  }

  .customers-data-table .id-scroll-column:hover .tooltip-id-column {
    visibility: visible;
  }

  .customers-data-table .id-scroll-column .tooltip-id-column {
    visibility: hidden;
    width: fit-content;
    text-align: center;
    padding: 5px;
    background-color: #f9f9f9;
    color: #5C697E;
    border: 1px solid #ddd;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1;
  }

  .scroll-content::-webkit-scrollbar {
    width: 2px;
    height: 3px;
    background-color: #F5F5F5;
  }

  .scroll-content::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 4px;
  }

  .scroll-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .scroll-content {
    overflow: hidden;
    white-space: nowrap;
  }

  .customers-data-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 5px;
    }

    th {
      background-color: #f2f2f2;
      border-bottom: 1px solid #000;
      font-size: 12px;
      height: 21px;
      line-height: 14px;
      @include fontStyles;
    }

    tr {
      @include fontStyles;
      font-size: 12px;
      line-height: 12px;
    }

    .market-flag {
      padding: 8px;
    }

    .flag-icon,
    .bag-icon {
      width: 18px;
    }

    .bag-icon {
      padding-bottom: 5px;
    }
  }
}

.customers-data-table {
  td {
    color: #5C697E;
  }

  td img {
    height: 16px;
  }

  .customers-table-name {
    font-size: 12px;
    margin: 10px 0 5px 0;
  }
}

.customers-data-table .id-column {
  width: 60px;
}

.customers-data-table .market-column {
  width: 40px;
}

.customers-data-table .date-column {
  width: 50px;
}

.customers-data-table .status-column {
  width: 50px;
}

.customers-data-table .via-column {
  width: 50px;
}

.customers-data-table .transactions-column {
  width: 70px;
}

.customers-data-table .bank-column {
  width: 80px;
}

.customers-data-table-container.expanded .customers-data-table .bank-column {
  width: 50px;
}

.customers-data-table .empty-column {
  width: 10px;
}

.customers-data-table .deviation-column {
  width: 120px;
}

.data-grid .customers-data-table-container .table-data-wrapper .customers-data-table tbody .id-scroll-column {
  position: relative;
  overflow: visible;
}

.bg-white-live-data-customers {
  background: none no-repeat scroll 0 0 #fff;
  position: relative;
  padding: 0.3rem 1rem 2rem;
  border-radius: 1.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.customers-table-inner-header,
.form-wrapper,
.filter-container-customers,
.data-container-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expand-button-customers {
  border: none;
  background-color: #FFFFFF;
}

.form-wrapper {
  padding: 1rem 0;
  flex: 1 1;
  overflow: hidden;
}

.customers-filer-container,
.customers-filter-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.filter-container-customers {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.customers-filter-container label,
.customers-filter-container select {
  margin-bottom: 5px;
}

.customers-filter-container label {
  display: inline-block;
  font-size: 12px;
  color: #5b6a83;
}

.customers-filter-container select {
  height: 37px;
  color: #5b6a83;
  font-size: 13px;
  transition: 0.2s;
  font-weight: 400;
  line-height: 37px;
  position: relative;
  border-radius: 6px;
  padding: 0 0.375rem;
  background-color: white;
  border: 1px solid #e3e7ee;
  min-width: 120px;
  max-width: 120px;
}

.data-container-wrapper {
  flex-wrap: wrap;
  display: contents;
  padding-bottom: 40px;
}

.inner-content-customers {
  display: grid;
  gap: 20px;
}

@media (max-width: 1240px) {
  .inner-content-customers .data-container-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .data-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.inner-content-customers.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  overflow: auto;
  padding: 20px 10px;
}

.inner-content-customers.expanded .data-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 5px;
}

.inner-content-customers.expanded .filter-container-customers {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  width: fit-content;
}

.header-on-expand {
  background-color: #5b6a83;
}

.data-container-wrapper.expanded {
  padding: 5px;
}

.customers-table-inner-header.expanded {
  background-color: #f8f9fc;
  padding: 5px;
}

.inner-content-customers.expanded .table-wrapper-customers {
  overflow-x: hidden;
  width: 100%;
}

.inner-content-customers.expanded .customers-data-table {
  width: 100%;
}

.expanded .data-grid .customers-data-table th,
.expanded .data-grid .customers-data-table td {
  padding: 3px;
}

.customers-table-inner-header h3 {
  padding-top: 5px;
}

.error-message-column {
  word-wrap: break-word;
  white-space: normal;
  max-width: 200px;
}

.error-msg-icon {
  margin-right: 5px;
}

.filter-container-customers {
  .clear-filter-btn {
    margin: 15px 10px 0;
  }
}