//UTILITIES
@import './utilities/utilities';

.reports-header {

  a {

    margin-right: 10px;
  }


}

//STYLING FOR UPPER ROW STARTS HERE
.container-upper-row {
  margin-bottom: 10px;
  min-height: 120px;

  .upper-row-th {
    font-size: $fs-body;
    font-weight: $fw-regular;

    th {
      color: rgb(160, 160, 160);
      background-color: white;
      border: none;
      text-wrap: nowrap;
      font-weight: normal;
      width: 20%;
      border-top: none;
      border-bottom: none;
      padding: 12px 16px;
    }
  }

  .upper-row-tr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    td {
      padding: 0 0 12px 16px;
      border: none;
      width: 20%;
      font-weight: normal;
    }
  }
}