.wrapper {
    flex-direction: row;
    background-color: #f4f6fb;
}

.main-container{
    display: flex;
    flex-direction: row;
      background-color: #f4f6fb;
      height: calc(100vh - 90px);
}
