.organization-provider-filter-container label,
.org-consent-select label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #8591a6;
  margin: 5px 0px;
}

select.select-filter-organization-provider {
  height: 37px;
  color: hsl(0, 0%, 50%);
  font-size: 12px;
  transition: 0.2s;
  font-weight: 400;
  line-height: 37px;
  position: relative;
  border-radius: 6px;
  padding: 0 0.375rem;
  background-color: white;
  border: 1px solid #e3e7ee;
  margin: 0 0px 15px;
  width: 100%;
}

.email-sms-template-input {
  position: relative;
  z-index: 99;
}

.email-sms-template-input .email-sms-select-org-dropdown {
  width: 100%;
}

.email-sms-editor-container {
  flex-direction: column;
}

.email-sms-editor-container .tox {
  margin-bottom: 15px;
}

.org-consent-select .email-sms-select-org-dropdown {
  width: 100%;
}

.org-consent-select .email-sms-select-org-dropdown span+span+div {
  border: 1px solid #e3e7ee;
  color: hsl(0, 0%, 50%);
  font-size: 12px;
  transition: 0.2s;
  font-weight: 400;
}

.filter-buttons {
  display: flex;
  margin: 30px 0 0;
}

.filter-buttons button.service-button {
  height: 37px;
}

.previewPopup_inner {
  position: absolute;
  width: 850px;
  height: 900px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: #fff;
  border: 1px solid rgb(236, 232, 232);
  border-radius: 10px;
  padding: 50px 20px 20px 20px;
}

.previewPopup_inner .close {
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 15px;
}


/* Breadcrumb */

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #eee;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


ul.breadcrumb li+li:before {
  padding: 8px;
  color: #01447e;
  content: ">\00a0";
}

ul.breadcrumb li span {
  color: #0275d8;
}

ul.breadcrumb li:first-child span {
  text-decoration: underline;
}

ul.breadcrumb li:first-child span:hover {
  color: #01447e;
  cursor: pointer;
}

.nodiffertext {
  letter-spacing: 1px;
  text-align: center;
  padding: 1rem 2rem;
}

.service-button {
  display: flex;
  margin-left: auto;
}

.service-button.big-w {
  padding-left: 3rem;
  padding-right: 3rem;
}

.org-breadcumb-button {
  display: flex;
}

.diff-titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.diff-titles-left {
  flex: 1;
  text-align: left;
  padding-right: 1rem;
}

.diff-titles-right {
  flex: 1;
}

.organization-provider-filter-container .email-sms-select-org-dropdown {
  width: 100%;
}

.associated-org-details {
  margin: 1.5rem 0 0 0;
  padding: 1rem;
  background-color: #e3e7ee;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.associated-org-details li {
  list-style: disc;
  list-style-position: inside;
}

.email-sms-editor-container .tox .tox-editor-container {
  z-index: 0;
}

.diff-view {
  font-family: monospace;
}

.line-added {
  background-color: #d4fcbc;
}

.line-removed {
  background-color: #fbb6c2;
}

.line-added,
.line-removed {
  white-space: pre-wrap;
}

.char-added {
  background-color: #d4fcbc;
  color: green;
}

.char-removed {
  background-color: #fbb6c2;
  color: red;
}

.diff-view {
  display: flex;
  justify-content: space-between;
}

.diff-column {
  width: 50%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.remove-text-highlight {
  background-color: #fdeff0;
}

.new-text-highlight {
  background-color: #eaffee;
}

.char-added {
  background-color: rgba(0, 194, 129, .4);
  color: black;
}

.char-removed {
  background-color: rgba(245, 61, 61, .4);
  color: black;
}

.pre-difference {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}