.upload-bank-icon {
  padding: 0 20px 6px;
}

.icon-box {
  position: relative;
}

.icon-box input {
  width: 100%;
  display: block;
  cursor: pointer;
}

.icon-box input:before {
  content: "Choose File";
  top: 0;
  left: auto;
  width: 91px;
  height: 30px;
  color: #FFFFFF;
  display: flex;
  font-size: 14px;
  border-radius: 3px;
  position: absolute;
  align-items: center;
  background: #3E85F9;
  justify-content: center;
}