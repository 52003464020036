::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

html {
    -webkit-text-size-adjust: 100%;
}

body {
    overflow-y: hidden;
}

.tc-page-container {
    flex: 5;
    background: #f4f6fb;
    height: calc(100vh - 110px);
    padding: 0 !important;
}

/* MAIN */
.tc-table-wrapper {
    display: block;
    position: relative;
    height: calc(100vh - 110px);
    overflow: auto;
    padding: 0 2rem 0 0;
}

.tc-table {
    padding: 0 0.7rem;
}

.tc-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
}

.tc-h3 {
    color: #596980;
    padding: 0;
}

.tc-btns {
    display: inline-block;
}

.create-subs-btn-div {
    display: inline-block;
    padding-left: 1.5rem;
}

.create-subs-btn {
    background: #3E85F9;
    color: #FFF;
    border: 1px solid #3E85F9;
    border-radius: 6px;
    font-size: 13px;
    width: 124px;
    height: 37px;
    margin-bottom: 0.6rem;
    cursor: pointer;
}

.tests-btn {
    background: #3E85F9;
    color: #FFF;
    border: 1px solid #3E85F9;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 12px;
    width: 102px;
    height: 37px;
    margin-bottom: 0.6rem;
    cursor: pointer;
}

.subs-btn {
    background: #FFF;
    color: #8591A6;
    border: 1px solid #E3E7EE;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 12px;
    width: 102px;
    height: 37px;
    margin-bottom: 0.6rem;
    cursor: pointer;
}

.tc-white-bg {
    background: none no-repeat scroll 0 0 #fff;
    position: relative;
    padding: 0.5rem 1.5rem;
    border-radius: 1.5rem;
}

.top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 11px;
}

.statisctics-text {
    color: #596980;
    margin: 0;
}

.filters-and-calendar-div {
    display: inline-block;
}

.filters-btn-primary {
    background: #FFF;
    color: #5B6A83;
    border: 1px solid #E3E7EE;
    border-radius: 6px;
    font-size: 13px;
    width: 143px;
    height: 36px;
    margin-bottom: 0.6rem;
    cursor: pointer;
    margin: 0;
}

.filters-btn-secondary {
    background: #E8EDFA;
    color: #3E85F9;
    border: 1px solid #E8EDFA;
    border-radius: 6px;
    font-size: 13px;
    width: 143px;
    height: 36px;
    margin-bottom: 0.6rem;
    cursor: pointer;
    margin: 0;
}

.filters-btn-primary:focus, .filters-btn-secondary:focus {
    outline: none;
}

.calendar-div {
    display: inline-block;
    padding-left: 1rem;
}

.calendar {
    background: #FFFFFF;
    border: 1px solid #E3E7EE;
    box-sizing: border-box;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 224px;
    height: 37px;
    padding-left: 1rem;
    font-size: 13px;
    color: #596980;
    outline: none;
    margin: 0;
}

.tc-calendar-icon {
    background-color: #3e85f9;
    margin-left: 210px;
    margin-top: -35px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 30px;
    padding: 5.5px 5px;
    height: 35px;
}

.tc-icon-position {
    transform: translate(3px);
}

#tc-date-range-picker {
    color: #6c757d;
    padding: 0 0 0 12px;
    font-size: 0.9rem;
    height: 35px;
    width: 13.2rem;
}

.middle-row {
    display: flex;
    justify-content: space-between;
    align-self: center;
}

.div-one, .div-two, .div-three {
    padding: 18px 0 0 0;
}

.section-one, .section-two, .section-three {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 22vw;
    height: 5rem;
    border-radius: 10px;
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.section-one {
    background-color: rgba(242, 247, 255, 1);
}

.section-two {
    background-color: rgba(242, 255, 251, 1);
}

.section-three {
    background-color: rgba(255, 248, 242, 1);
}

.tc-p {
    font-size: 12px;
    color: #8591A6;
    margin: 0;
}

.tc-inactive-p {
    color: #e94444;
    font-size: 14px;
    font-family: SF Pro Display !important;
    font-weight: 400 !important;
    vertical-align: middle;
    cursor: pointer;
    margin: 0;
}

.icon-one, .icon-two, .icon-three {
    font-size: 2.6rem;
    align-self: flex-end;
    transform: translateY(-55px);
}

.icon-one {
    color: #3E85F9;
}

.icon-two {
    color: #00C48C;
}

.icon-three {
    color: #F2994A;
}

.bottom-row {
    display: flex;
    justify-content: space-between;
    padding: 1.8rem 0.7rem 1rem 0.7rem;
}

#tc-search {
    background: url('https://api.iconify.design/mdi/magnify.svg?color=%239ca5b4&width=22') no-repeat scroll 15rem 6px;
    background-color: #F4F6FB !important;
    color: #6c757d;
    font-style: italic;
    font-size: 12px;
    border-radius: 25px;
    width: 17rem;
    height: 2.1875rem;
    border: none;
    padding: 0 42px 0 17px;
}

#tc-search:focus {
    outline: none;
}

.tc-thead {
    background: #F4F6FB;
    color: #596980;
    border-bottom: 2px solid #F4F6FB !important;
    border-top: 1px solid #F4F6FB !important;
}

.tc-name, .tc-source, .tc-product, .tc-type, .tc-market, .tc-from-to, .tc-requests {
    font-size: 14px;
    height: 48px;
    font-weight: 400 !important;
    font-family: SF Pro Display;
    vertical-align: middle;
}

.tc-name {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.tc-product {
    width: 6rem;
}

.tc-source {
    width: 6rem;
}

.tc-requests {
    width: 10rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.tc-type {
    width: 10rem;
}

.tc-market {
    width: 7.5rem;
}

.tc-from-to {
    width: 14rem;
}

#tc-dropdown {
    background: url('https://api.iconify.design/akar-icons/chevron-down.svg?color=%236c757d&width=15') no-repeat scroll 8.5rem 1rem;
}

.td-name, .td-source, .td-product, .td-type, .td-market, .td-from-to, .td-requests {
    font-size: 14px;
    font-family: SF Pro Display !important;
    font-weight: 400 !important;
    color: #596980;
    height: 48px;
    vertical-align: middle;
    cursor: pointer;
}

.pagination-wrapper {
    text-align: center;
    padding: 2rem 0rem 0 0;
}

/* FILTERS */
.tc-top-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: 1.2rem 0 0.35rem 0;
}

.tc-input-wrapper {
    display: inline-block;
    min-width: auto;
}

.tc-p {
    color: #8591A6;
    font-size: 12px;
    margin: 0;
    padding-bottom: 0.2rem;
}

#tc-select {
    padding: 2px 10px;
    width: 10vw;
    height: 40px;
    color: #6c757d;
    font-size: 13px;
    font-family: SF Pro Display;
}

.tc-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 2px 10px;
    width: 22vw;
    height: 37px;
    color: #6c757d;
    font-size: 13px;
    font-family: SF Pro Display;
}

.tc-input::placeholder {
    color: #C5C7CC;
    font-size: 13px;
}

.tc-input:focus {
    outline: 0.1px solid #86b7fe;
}

.tc-input[type=text] {
    padding: 2px 10px;
    display: inline-block;
    box-sizing: border-box;
    color: #6c757d;
    font-size: 1rem;
    font-family: SF Pro Display;
}

.tc-apply-btn {
    background: #3E85F9;
    color: #FFF;
    border: 1px solid #3E85F9;
    border-radius: 3px;
    font-size: 13px;
    width: 10vw;
    height: 40px;
    cursor: pointer;
}

.tc-dropdown-icon {
    position: absolute;
    top: 114px;
    transform: translateX(-22px);
}

/* REACT DATERANGEPICKER CUSTOMIZATION FRIENDLY */
.daterangepicker.opensright {
    margin-right: 20px !important;
}

.daterangepicker:before {
    border-right: none !important;
    border-left: none !important;
}

.daterangepicker:after {
    top: 0 !important;
}

.table .mui-collapse-container {
    border: none !important;
    padding: 0 !important;
}