.bg-white-editUser {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
  margin: 0 0 30px;
  max-height: 80%;
  overflow-y: auto;
}

.note-modal {
  padding: 20px 70px 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f4f6fb;
  margin: 20px 0 0 0;
}

.note-modal img {
  margin: 0 10px 0 0;
}

.note-modal .note-info {
  width: calc(100% - 30px);
}

.note-modal .note-info p {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #5b6a83;
  margin: 0;
}

.note-modal .note-info p span {
  display: inline-block;
  font-weight: bold;
}

.create-button {
  border: none;
  background: #3e85f9;
  border-radius: 6px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  width: 166px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.alert-hidden {
  opacity: 0;
  display: none;
}

.excludeDropdown {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  background: #fff;
  background-image: none;
  color: #5b6a83 !important;
  font-size: 13px;
  width: 276px;
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url("../../assets/images/icon_arrow_down.png");
  background-repeat: no-repeat;
  background-size: 10px 8px;
  background-position: 250px 12px;
}

.labelFormUser {
  margin-bottom: 10px;
}

.alert-shown {
  opacity: 1;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  display: block;
}

.alert-hidden {
  opacity: 0;
  display: none;
}

.excludeDropdown {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  background: #fff;
  background-image: none;
  color: #5b6a83 !important;
  font-size: 13px;
  width: 276px;
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url("../../assets/images/icon_arrow_down.png");
  background-repeat: no-repeat;
  background-size: 10px 8px;
  background-position: 250px 12px;
}

.alert-hidden {
  opacity: 0;
  display: none;
}

.excludeDropdown {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  background: #fff;
  background-image: none;
  color: #5b6a83 !important;
  font-size: 13px;
  width: 276px;
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url("../../assets/images/icon_arrow_down.png");
  background-repeat: no-repeat;
  background-size: 10px 8px;
  background-position: 250px 12px;
}

.esu-icon-wrapper {
  padding: 0 2rem 0 1rem;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  cursor: pointer;
}

div.form-group.edit {
  margin-left: 45px;
}

.flex-select {
  width: 100%;
  display: flex;

  select {
    width: 80px;
    background-position: right 7px top 13px;
  }
}

/*# sourceMappingURL=EditSomeUser.css.map */

.edit-user-container {
  display: flex;
  flex-direction: row;

  @media (max-width: 1300px) {
    display: flex;
    justify-content: left;
    flex-direction: column;
  }
}

.edit-user-access-settings-container {
  display: flex;
  flex-direction: column;

  @media (max-width: 1300px) {
    margin-top: 2rem;
  }

  .form-check-input {
    cursor: pointer;
  }
  h4 {
    font-size: 18px;
    padding: 0;
    margin: 0 24px;

    @media (max-width: 1300px) {
      text-align: center;
      margin-bottom: 2rem;
    }
    
  }

  .edit-user-settings-switches-container {
    column-count: 3;
    margin: 0.5rem;
   
    @media (max-width: 1300px) {
    margin: auto;
    }
    
    @media (min-width: 1500px) {
      column-count: 4;
  }
  
  .edit-user-access-settings-checks-container {
    margin: 8px 12px;
    font-size: 14px;
    width: 230px;
    color: #596980;
    
    .edit-user-access-settings-main-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
  
      margin: 0;
      padding: 0;
      height: 16px;
      min-height: 14px;
  
      .form-check-label {
        margin-left: 0;
        font-size: 14px;
        font-weight: bolder;
      }
    }
  
    .edit-user-access-settings-sub-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      font-weight: normal;
  
      padding: 0;
      height: 16px;
      min-height: 14px;
      margin: 0;
      }
    }
  }
}

.form-group-button{
  display: flex;
  flex-direction: left;
  align-items: left;
  justify-content: left;
  margin-top: 2rem;
}