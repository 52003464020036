.as-h3 {
  color: #596980;
  padding: 0;
  cursor: pointer;
}

.as-bottom-row {
  display: flex;
  justify-content: flex-end;
  padding: 1.8rem 0.7rem 1rem 0.7rem;
}

.as-subs-btn {
  background: #3E85F9;
  color: #FFF;
  border: 1px solid #3E85F9;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
  width: 102px;
  height: 37px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.as-tests-btn {
  background: #FFF;
  color: #8591A6;
  border: 1px solid #E3E7EE;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 12px;
  width: 102px;
  height: 37px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.as-filters-wrapper {
  display: inline-block;
  padding-right: 1rem;
}

.as-filters-btn {
  background: #FFF;
  color: #5B6A83;
  border: 1px solid #E3E7EE;
  border-radius: 6px;
  font-size: 13px;
  width: 143px;
  height: 36px;
  margin-bottom: 0.6rem;
  cursor: pointer;
  margin: 0;
}

.as-name, .as-email, .as-type, .as-market, .as-product, .as-req, .as-from-to, .as-action {
  font-size: 14px;
  height: 48px;
  font-weight: 400 !important;
  font-family: SF Pro Display;
  vertical-align: middle;
}

.as-name {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.as-action {
  margin-left: 1rem;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.td-as-name, .td-as-email, .td-as-type, .td-as-market, .td-as-product, .td-as-req, .td-as-from-to, .td-as-action {
  font-size: 14px;
  color: #596980;
  height: 48px;
  vertical-align: middle;
}

.as-email, .td-as-email, .td-as-market, .as-market, .as-req, .td-as-req, .as-action, .td-as-action {
  padding: 0 !important;
}

.as-icons {
  display: flex;
}

.as-edit-icon, .as-trash-icon {
  font-size: 1.2rem;
  color: #8591A6;
  cursor: pointer;
}

.as-edit-icon {
  margin-right: 0.5rem;
}

/* MODAL */
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.198) !important;
}

.as-delete-icon {
  color: #FF6262;
  font-size: 4rem;
  position: relative;
  left: 7rem;
}

.as-modal-p {
  color: #5B6A83;
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  margin: 1rem 3rem 1.8rem 3rem;
}

.as-modal-btns {
  display: flex;
  justify-content: center;
}

.as-cancel-btn-wrapper {
  padding-right: 0.5rem;
}

.as-delete-btn-wrapper {
  padding-left: 0.5rem;
}

.as-cancel-btn {
  color: #3E85F9;
  background: #fff;
  border: 1px solid #3E85F9;
  border-radius: 6px;
  font-size: 14px;
  width: 102px;
  height: 37px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.as-delete-btn {
  background: #FF6262;
  color: #fff;
  border: 1px solid #FF6262;
  border-radius: 6px;
  font-size: 14px;
  width: 102px;
  height: 37px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

/* FILTERS */
.as-filters-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 100%;
  padding: 0.2rem 0.7rem 0.6rem 0.7rem;
}

.as-input-wrapper {
  display: inline-block;
  min-width: auto;
}

.as-filters-p {
  color: #8591A6;
  font-size: 12px;
  margin: 0;
  padding-bottom: 0.2rem;
}

#as-select {
  padding: 2px 10px;
  width: 10vw;
  height: 40px;
  color: #6c757d;
  border: 1px solid #F0F0F0;
  font-size: 13px;
  font-family: SF Pro Display;
}

.as-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 10px;
  width: 22vw;
  height: 37px;
  color: #6c757d;
  font-size: 13px;
  font-family: SF Pro Display;
}

.as-dropdown-icon {
  position: absolute;
  top: 132px;
  transform: translateX(-22px);
}

.as-select::placeholder {
  color: #C5C7CC;
  font-size: 13px;
}

.as-select:focus {
  outline: 0.1px solid #86b7fe;
}

.as-select[type=text] {
  padding: 2px 10px;
  display: inline-block;
  box-sizing: border-box;
  color: #6c757d;
  font-size: 1rem;
  font-family: SF Pro Display;
}

.as-apply-btn {
  background: #3E85F9;
  color: #FFF;
  border: 1px solid #3E85F9;
  border-radius: 3px;
  font-size: 13px;
  width: 10vw;
  height: 40px;
  cursor: pointer;
}

.as-inactive-p {
  color: #e94444;
  font-size: 14px;
  font-family: SF Pro Display !important;
  font-weight: 400 !important;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
}

.as-continuously-p {
  color: #596980;
  font-size: 14px;
  font-family: SF Pro Display !important;
  font-weight: 400 !important;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
}