.bg-white-createUser{
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
  margin: 0 0 30px;
  max-height: 80%;
  overflow-y: auto;
}

.note-modal {
    padding: 20px 70px 20px 40px;
    display: flex;
    background: #F4F6FB;
    margin: 20px 0 0 0;
  }
  .note-modal img {
    margin: 0 10px 0 0;
  }
  .note-modal .note-info {
    width: calc(100% - 30px);
  }
  .note-modal .note-info p {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #5B6A83;
    margin: 0;
  }
  .note-modal .note-info p span {
    display: inline-block;
    font-weight: bold;
  }

  .create-button-user {
    border: none;
    background: #3E85F9;
    border-radius: 6px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    width: 166px;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 20px;
  }

.labelCreateFormUser{
  margin-bottom: 5px;
  color:#596989;
  font-size: 13px;
}

.alert-shown {
  opacity: 1;
  transition: all 200ms ease-in-out;
  display: block;

}

.alert-hidden {
  opacity: 0;
  display: none;
}

.excludeDropdown {
  outline: 0;
  box-shadow: none;
  border: 1px solid #ccc!important;
  border-radius: 5px;
  background: #fff;
  background-image: none;
  color: #5B6A83 !important;
  font-size: 13px;
  width: 276px;
  height: 35px;
  padding: 5px;
  padding-left: 10px;

  appearance:none;
  -webkit-appearance:none;
  -moz-appearance:none;
  -ms-appearance:none;
  background:url('../../assets/images/icon_arrow_down.png');
  background-repeat:no-repeat;
  background-size:10px 8px;
  background-position:250px 12px;
}

.cnu-input-wrapper {
  padding-top: 10px;
}

.flex-select {
  width: 100%;
  display: flex;
  select {
    width: 80px;
    background-position: right 7px top 13px;
  }
}

.new-user-page-container{
  margin: 0;
  flex: 5 1;
  background: #f4f6fb;
  height: calc(100vh - 110px);
  padding: 0 15px 0 15px !important;
  width: 100%;
  overflow-x: hidden;
}