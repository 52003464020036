.endpoint-selects-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@mixin endpoint-button-style {
  background-color: #3e85f9;
  color: white;
  border: none;
  height: 2rem;
  width: 6rem;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #0a62ef;
    color: white;
  }
}

.endpoint-button {
  @include endpoint-button-style;
}

.endpoint-header {
  color: #596980;
}

.endpoint-create-button {
  @include endpoint-button-style;
  width: 8rem;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.endpoint-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.endpoint-modal-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 35rem;
  max-height: 80vh;
  overflow-y: auto;
  color: #596980;
}

.endpoint-modal-buttons-container {
  display: flex;
  justify-content: space-between;
}

.endpoint-submit-button,
.endpoint-close-button {
  @include endpoint-button-style;
}

.endpoint-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 2rem;
}

.endpoint-form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.endpoint-form-group-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.endpoint-label {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.endpoint-error{
  color: red;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.endpoint-organizationList-label {
  font-size: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  color: #4a5a70;
  margin-bottom: 1rem;
}

.endpoint-input {
  width: 20rem;
  height: 2.4rem;
  padding: 0.1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #4a5a70;
}

.endpoint-checkbox {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1rem;
}

.endpoint-modal-buttons-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.endpoint-expanded-content {
  background-color: whitesmoke;
  border: 1px solid #ccc;
}

.endpoint-checkbox-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}

.endpoint-select-org-dropdown {
  width: 20rem;
  font-family: "SF Pro Display";
  font-size: 1rem;
  color: #596980;
}

.endpoint-organizations-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.endpoint-organization-item.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.endpoint-expanded-content {
  background-color: whitesmoke;
  border: 1px solid #ccc;
  overflow: hidden;
}

.endpoint-organization-item {
  background-color: whitesmoke;
  padding: 0.5rem;
  border-bottom: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  padding-right: 10rem;
  align-items: center;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.endpoint-no-message {
  color: #596980;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.endpoint-expand-button {
  border: none;
  margin: 0.5rem;
  width: 1.3rem;
  height: 1.3rem;
  background-color: transparent;
}

.endpoint-expand-organization-td {
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: #616f92;
}

.endpoint-info-group {
  margin-top: 2rem;
}

@keyframes fadeInSlideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.endpoint-flow-ld-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #596980;
}

.endpoint-details-header-line{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.endpoint-statistics-section {
  text-align: center;
  margin: 2rem;
  color: #596980
}

.endpoint-statistics-header {
  font-size: 1.8rem;
}

.endpoint-statistics {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.endpoint-statistics-value {
  font-size: 1.2rem;
  color: #3e85f9
}

.endpoint-details-test-button {
  display: flex;
  align-items: center;
}

.endpoint-details-tbody {
  display: block;
  max-height: 20rem;
  overflow-y: auto;
  td{
    font-size: 14px;
  }
}

.endpoint-tbody{
  display: block;
  max-height: 35rem;
  overflow-y: auto;
  td{
    font-size: 14px;
    padding-left: 0.5rem;
  }
}

.endpoint-details-thead, .endpoint-details-tbody tr, .endpoint-tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.endpoint-details-thead th {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  border-bottom: 2px solid #ddd;
}

.endpoint-expanded-response {
  max-height: 13rem;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 0.5rem;
  margin-top: 1rem;
}

.endpoint-expanded-reponse-pre {
  white-space: pre-wrap;
  padding: 0.5rem;
  margin-top: 1rem;
}

.endpoint-arrow-icon{
  margin-left: 0.5rem;
  font-size: 1.8rem;
}