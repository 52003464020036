.access-codes-container {
    flex: 5;
    background: #f4f6fb;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: SF Pro Display;
    overflow: auto;
}

.left-boxes-access {
    display: flex;
    flex-direction: column;
    width: 40%;
    /* height: 600px; */
}

.generate-box {
    background-color: white;
    height: 210px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.generate-background {
    background-image: url('../../assets/images/access_codes_bkgd.svg');
    color: #F4F6FB;
    padding: 10px 20px 10px 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.access-code-enter-email-text {
    padding-top: 10px;
    padding-left: 20px;
}

.dropdown-access-li {
    font-size: 14px;
    color: #596980 !important;
}


.input-access-email-box {
    margin: 10px 20px 0px 20px;
    width: 100%;
}

.api-access-input {
    width: 60%;
    margin: 20px 18px 0px 0px;
    height: 37px;
    border-radius: 5px;
    border-color: #E3E7EE;
    border-style: solid;
    color: #596980;
    border-width: 1px;
    padding-left: 5px;
    background-color: white;
}

.generate-access-code-button {
    background-color: #3E85F9;
    color: white;
    height: 37px;
    border-color: transparent;
    border-radius: 5px;
    font-size: 13px;
    width: 28%;
    margin-right: 20px;
}

.disabled-button-style {
    background-color: #8D96A9;
}

.send-access-code-box {
    background-color: white;
    height: 600px;
    border-radius: 10px;
    width: 100%;
}

.send-access-code-box>.sendcode-div {
    padding-top: 100px;
}

.send-access-code-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #596980;
    padding: 20px 20px 0px 0px;
}

.send-access-code-form {
    width: 92%;
    height: 37px;
    border-radius: 5px;
    border-color: #E3E7EE;
    border-style: solid;
    font-size: 14px;
    border-width: 1px;
    padding-left: 8px;
}

.send-access-code-form::placeholder,
.sendcode-phone::placeholder {
    color: #C5C7CC !important;
    font-size: 14px;
}

.pre-phone {
    height: 37px;
    width: 19%;
    border-radius: 5px;
    border-color: #E3E7EE;
    background-color: white;
    border-style: solid;
    font-size: 14px;
    border-width: 1px;
    padding: 0px 8px 0px 8px;
    margin: 0px 10px 0px 20px;
    color: #596980;
}


.sendcode-phone {
    width: 71%;
    height: 37px;
    border-radius: 5px;
    border-color: #E3E7EE;
    border-style: solid;
    font-size: 14px;
    border-width: 1px;
    padding-left: 8px;
}

.send-button-access {
    background-color: #3E85F9;
    color: white;
    height: 37px;
    border-color: transparent;
    border-radius: 5px;
    font-size: 13px;
    width: 25%;
    margin: 22px 20px;
}

.accescode-overview {
    background-color: white;
    margin-left: 25px;
    height: 600px;
    width: 56%;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
}

.demo-headers {
    color: #596980;
    background-color: #f4f6fb;
    border-bottom: 2px solid transparent;
    font-size: 14px !important;
    width: 100%;
    top: 0px;
}

.demo-head-accesscode {
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.demo-head-date {
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.code-status {
    height: 50px;
    text-align: center;
}

.demo-info {
    color: #596980;
    border-bottom: 2px solid transparent;
    border-radius: 4px;
    font-size: 14px !important;
    width: 100%;
}

.code-info {
    border-bottom: #f4f6fb 2px solid !important;
    text-align: center;
    width: 20%;
}

.demo-status {
    color: #28a745;
}

.demo-status-red {
    color: #FF647C;
}

.demo-accesscode,
.demo-status,
.demo-user,
.demo-date {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.pagination-access-codes {
    margin-top: 20px;
}

.apiDocumentation input {
    border: 1px solid #d7dadf;
    color: #5c697e;
    font-size: 14px;
    height: 24px;
    width: 245px;
    border-radius: 5px;
    padding: 4px 5px;
}

.apiDocumentation h3 {
    margin: 45px 0;
}

.certificate-removal-hard-deletion .inner-hard-deletion .apiDocumentation ul {
    margin: 0 0 18px;
}

.certificate-removal-hard-deletion .inner-hard-deletion .apiDocumentation .label-form-btns button.delete-b {
    background: #3E85F9;
    border: 1px solid #3E85F9;
    cursor: pointer;
}

.certificate-removal-hard-deletion .inner-hard-deletion .apiDocumentation .label-form-btns button.delete-disabled {
    color: #8591A6;
    background: #D6DAE0;
    cursor: not-allowed;
    border: 1px solid #D6DAE0;
}

.emailDetails {
    display: flex;
    color: #5c697e;
    font-size: 14px;
    margin: 0 0 44px;
}

.emailDetails span {
    margin-right: 5px;
}

.certificate-removal-hard-deletion .inner-hard-deletion .apiDocumentation ul li {
    display: flex;
    align-items: center;
    color: #5c697e;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.certificate-removal-hard-deletion .inner-hard-deletion .apiDocumentation ul li span {
    width: 150px;
    padding: 0;
}

.apiDocumentation textarea {
    margin-bottom: 67px;
    height: 101px;
    resize: none;
    color: #5c697e;
}

.apiDocumentation textarea::placeholder {
    color: #c5c7cc99;
}