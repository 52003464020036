// Utilities
@import './utilities/utilities';

//HEADINGS
h3 {
  color: $base-dark-grey;
  font-size: $fs-heading;
  font-weight: $fw-regular;
  line-height: 35.8px;
}

h4,
.h4 {
  color: $base-dark-grey;
  font-size: $fs-title;
  font-weight: $fw-bold;
  line-height: 23.87px;
}

h5,
.h5 {
  color: $base-dark-grey;
  font-size: $fs-subtitle;
  font-weight: $fw-bold;
  line-height: 21.48px;
}

h6,
.h6 {
  color: $base-dark-grey;
  font-size: $fs-body;
  line-height: 17px;
  text-align: center;
  text-decoration-line: underline;
}

.page-container {
  flex: 5;
  background-color: #f4f6fb;
  height: calc(100vh - 110px);
}

.ld-container {
  display: flex;
  flex-direction: column;
}

// .live-data-container{
//   overflow-x: hidden
// }

//STYLING FOR TOP INPUT/FILTER FIELD
.ld-search-container,
.ld-form-container {
  @include flexSpaceBetween;
  height: 37px;
  width: 60%;

  @media only screen and (min-width: 1900px) {
    width: 84px;
  }

  @media only screen and (min-width: 2300px) {
    width: 90px;
  }

  .live-data-daterange {
    border-radius: 6px;
    border: 1px solid #e3e7ee;
    height: 37px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.03em;
    color: #5b6a83;
    padding: 0.375rem 0.75rem;
    margin-right: 10px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    width: 190px;
    background-color: white;
    z-index: -1;
  }

  #ld-form-container {

    .ld-form-input-field,
    #form-date-field {
      border-radius: 6px;
      border: 1px solid #e3e7ee;
      height: 37px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.03em;
      color: #5b6a83;
      padding: 0.375rem 0.75rem;
      margin-right: 10px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      width: 190px;
      background-color: white;

      &:focus {
        outline: none !important;
        border: 0.1px solid #62a4eb;
      }

      @media only screen and (min-width: 1900px) {
        width: 263px;
      }

      @media only screen and (min-width: 2300px) {
        width: 325px;
      }
    }

    #form-date-field {
      padding: 0.75rem 0 0.75rem 0.75rem;
      width: 200px;

      @media only screen and (min-width: 1900px) {
        width: 263px;
      }

      @media only screen and (min-width: 2300px) {
        width: 325px;
      }
    }
  }

  select {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;

    //A new custom made dropdown arrow
    background-image: linear-gradient(45deg, transparent 50%, #777980 50%),
      linear-gradient(135deg, #777980 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 0px 1.5em;
    background-repeat: no-repeat;

    @media only screen and (min-width: 1900px) {
      background-size: 7px 5px, 6px 5px, 0px 1.5em;
    }
  }

  .ld-calendar-icon {
    background-color: #3e85f9;
    margin-left: -35px;
    margin-top: 0.5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 30px;
    padding: 4.5px 6px 0px 7px;
    height: 36px;
    z-index: 10;
  }
}

.date-picker-container {
  display: flex;

  div.react-datepicker__year-wrapper {
    // max-width: 140px;
    display: inherit;
  }

  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__year-text--selected {
    border-radius: 0.3rem;
    background-color: #357ebd;
    /* color: #fff; */
  }

  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__year-text--selected:hover {
    border-radius: 0.3rem;
    background-color: #357ebd;
    /* color: #fff; */
  }

  .daterangepicker {
    top: 164px !important;
    left: 890px !important;

    @media only screen and (min-width: 2300px) {
      left: 1134px !important;
    }
  }

  .daterangepicker td.start-date,
  .daterangepicker td.start-date.active {
    background-color: #357ebd !important;
  }

  .daterangepicker td.end-date,
  .daterangepicker td.end-date.active {
    background-color: #357ebd !important;
  }

  .daterangepicker td.in-range {
    background-color: #fff !important;
  }

  .daterangepicker td.today.active {
    background-color: #357ebd !important;
  }

  .daterangepicker td.off,
  .daterangepicker td.off.in-range,
  .daterangepicker td.off.start-date,
  .daterangepicker td.off.end-date {
    background-color: #fff !important;
    border-color: transparent !important;
    color: #999 !important;
  }
}

//STYLING FOR TOP LIVE DATA SUMMARY FILED
.ld-statistics-field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;

  .ld-statistics-box {
    @include flexSpaceBetween;
    align-items: baseline;
    padding: 5px 10px 10px 10px;
    background-color: #fff;
    border-radius: 6px;
    width: 262px;
    height: 80px;
    flex: none;
    color: $base-light-grey;

    .ld-statistics {

      h4,
      .h4 {
        padding: 0;
        margin: 0;
        color: $base-dark-grey;
      }

      p,
      .ld-request-type {
        font-size: 12px;
        line-height: 14.32px;
        padding-top: 3px;
        padding-bottom: 12px;
        margin: 0;
      }

      p,
      .ld-request-difference {
        font-size: 10px;
        line-height: 11.93px;
        padding: 0;
        margin: 0;
      }
    }

    .ld-statistics-summary {
      display: flex;

      p {
        font-size: 12px;
        padding: 4px;
      }

      .ld-percentage-box {
        display: flex;
        align-items: baseline;
      }

      .ld-percentage-time-box {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (min-width: 1900px) {
  .ld-statistics-field-container {
    width: 52.5vw;

    .ld-statistics-box {
      width: 245px;
    }
  }
}

@media only screen and (min-width: 2300px) {
  .ld-statistics-field-container {
    .ld-statistics-box {
      width: 290px;
    }
  }
}

//STYLING FOR MAIN CONTAINER STARTS HERE
.ld-main-data-container-height {
  height: 1651px;
}

@media only screen and (min-width: 1450px) {
  .ld-main-data-container-height {
    height: 1632px;
  }
}

.ld-main-data-container {
  @include flexCenter(row);

  .ld-requests-container {
    display: flex;
    flex-direction: column;
    width: 63%;
    border-radius: 10px;
    margin-right: 1.5rem;
  }

  //STYLING FOR CHART STARTS HERE
  .ld-request-chart-container {
    width: 100%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    color: #5b6a83 !important;
    margin-bottom: 1.5rem;
  }

  //STYLING FOR TABLE STARTS HERE
  .ld-request-table-container {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .ld-request-table {
      border-collapse: collapse;
      position: relative;
      width: 90%;
      height: 37px;
      left: 6%;
    }

    .ld-request-table-header {
      padding: 20px;
    }

    .ld-request-table-head {
      color: $base-light-grey;
      font-size: $fs-body;
      font-weight: $fw-regular;
      background-color: #f4f6fb;

      th {
        color: $base-light-grey;
        padding: 10px 20px;
        border-radius: 4px;
        width: 20%;
        border-top: none;
        border-bottom: none;
      }

      .filter-icon {
        display: inline;
        padding-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .ld-request-table-row {
      td {
        padding: 14px 14px 14px 20px;
        font-size: $fs-body;
        font-weight: 400;
        color: $base-dark-grey;
        border: solid #f4f6fb;
        border-width: 1px 0;
      }
    }
  }

  //RECENT ACTIVITIES, RIGHT SIDEBAR, STARTS HERE
  .ld-recent-activities-sidebar-container {
    width: 35%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    overflow: auto;

    .ld-recent-activities-sidebar-header {
      @include flexSpaceBetween;
      margin-bottom: 9px;
    }

    .ld-recent-activities-ul-list {
      position: relative;
      padding-left: 0;
    }

    .ld-recent-activities-list-items {
      @include flexSpaceBetween;
      list-style: none;
      padding: 16px 0;
      width: 100%;
    }

    .ld-recent-activities-list-items-border {
      border-bottom: 1px solid #f4f6fb;
    }

    .ld-recent-activities-list-item {
      @include flexSpaceBetween;

      p,
      span {
        font-size: $fs-body;
        font-weight: 400;
        margin-bottom: $fs-body;
        color: $base-dark-grey;
        display: inline;
      }

      .list-icon {
        padding-right: 10px;
        position: relative;
        text-align: center;
        color: white;
      }

      .centered {
        position: absolute;
        top: 32%;
        left: 36%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 11px;
        letter-spacing: 0.5px;
        margin-bottom: 0;
      }

      .ld-recent-activities-desciption-box {
        padding-top: 5px;
      }
    }

    .ld-recent-activities-last-update {
      p {
        font-size: 11px;
        color: $base-light-grey;
      }
    }

    .tooltip-icon:hover {
      cursor: pointer;
    }

    .ld-tooltip {
      position: relative;
      display: inline-block;

      .ld-tooltiptext {
        visibility: hidden;
        width: 145px;
        background-color: #586989e9;
        color: #fff;
        text-align: center;
        padding: 7px 5px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        font-size: 13px;
        top: -5px;
        right: 105%;
      }
    }

    .ld-tooltip:hover .ld-tooltiptext {
      visibility: visible;
    }

    .ld-list-description {
      color: $base-light-grey;
      font-size: $fs-bread;
      font-weight: 400;
      line-height: 15.51px;
      margin: 0;
    }
  }
}

//Compare graph

.live-data-compare-graph-section {
  margin-top: 2%;
  border-radius: 10;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  height: fit-content;
  width: 98%;

  .highcharts-tooltip-live-data {
    font-weight: bolder;
  }
}


.live-data-container .react-select-custom {
  margin-right: 10px;
  width: 190px;
}

.live-data-graph {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12rem;
}

.live-data-graph-spinner {
  color: #28A744;
}