.bank-status-page-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 5 1;
  background: #f4f6fb;
  height: calc(100vh - 110px);
  width: 100%;

}

.bank-status-container {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.bank-status-page-container-main {
  margin: 0;
  flex: 5 1;
  background-color: #f4f6fb;
  height: calc(100vh - 110px);
  padding: 0 30px 0 0;
  overflow-x: hidden;
  overflow-y: scroll;
}