.page-container {
  flex: 5;
  background: #f4f6fb;
  height: calc(100vh - 70px);
  padding: 0 !important;
}

.cs-heading-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1.7rem;
}

.cs-icon-wrapper {
  padding: 0 2rem 0 1rem;
  transform: translateY(-2px);
  cursor: pointer;
}

.cs-arrow-icon {
  transform: translateY(-3px);
}

.cs-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 12vh);
  gap: 0.3rem;
  background: none no-repeat scroll 0 0 #fff;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  margin: 0 0.7rem 0 1rem;
  height: 68vh;
}

.cs-name-grid-wrapper {
  grid-column: 1;
  grid-row: 1;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-ssn-grid-wrapper {
  grid-column: 2;
  grid-row: 1;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-email-grid-wrapper {
  grid-column: 3;
  grid-row: 1;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-phone-grid-wrapper {
  grid-column: 1;
  grid-row: 2;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-certificate-grid-row {
  grid-column: 2;
  grid-row: 2;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-market-grid-wrapper {
  grid-column: 3;
  grid-row: 2;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-language-grid-wrapper {
  grid-column: 1;
  grid-row: 3;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-product-grid-wrapper {
  grid-column: 2;
  grid-row: 3;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-request-grid-container {
  grid-column: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  gap: 0 18px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-magrin-top {
  position: relative;
  bottom: 15px;
}

.cs-calendars-grid-wrapper {
  grid-column: 1;
  grid-row: 4;
  display: flex;
  flex-wrap: wrap;
  margin-left: 14px;
  padding: 10px 5px;
  position: relative;
  transform: translateX(0px);
  right: 0;
}

.cs-from-calendar {
  margin-right: 20px;
}

.cs-switch-grid-wrapper {
  grid-column: 2;
  grid-row: 4;
  align-self: flex-start;
  justify-self: flex-start;
  margin-left: 14px;
  position: relative;
  transform: translateX(0px);
  top: 25px;
}

.cs-switch {
  position: relative;
  left: 0;
  top: 10px;
}

.cs-btns-grid-container {
  grid-column: 3;
  grid-row: 5;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  position: relative;
  transform: translateX(0px);
  top: 1rem;
}

.cs-cancel-btn-wrapper {
  padding-right: 16px;
}

.cs-create-btn-wrapper {
  padding-left: 16px;
}

.cs-cancel-btn {
  background: #FFF;
  color: #3E85F9;
  border: 1px solid #3E85F9;
  border-radius: 3px;
  font-size: 12px;
  width: 9.9vw;
  height: 37px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.cs-create-btn {
  color: #FFF;
  background: #3E85F9;
  border: 1px solid #3E85F9;
  border-radius: 3px;
  font-size: 13px;
  width: 9.9vw;
  height: 37px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.cs-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #6c757d;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 13px;
  font-family: SF Pro Display;
  width: 22vw;
  height: 37px;
  padding: 2px 10px;
}

input.inherit {
  font-family: inherit;
}

.cs-input::placeholder {
  color: #C5C7CC;
  font-size: 13px;
}

.cs-input:focus {
  outline: 0.1px solid #86b7fe;
}

.cs-input[type=text] {
  color: #6c757d;
  font-size: 13px;
  font-family: SF Pro Display !important;
  display: inline-block;
  box-sizing: border-box;
  padding: 2px 10px;
}

.cs-p {
  color: #596980;
  font-size: 13px;
  margin: 0;
  padding-bottom: 0.2rem;
}

#cs-select {
  color: #6c757d;
  font-size: 13px;
  font-family: SF Pro Display;
  padding: 2px 10px;
  width: 22vw;
  height: 37px;
}

.cs-calendar {
  color: #8591A6;
  font-size: 13px;
  font-family: SF Pro Display;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 10px;
  width: 10.3vw;
  height: 37px;
  cursor: pointer;
}

.cs-calendar:focus {
  outline: 0.1px solid #86b7fe;
}

.cs-custom-input-p {
  padding: 6.5px 6.5px 6.5px 2px;
}

.cs-calendar-icon-container, .cs-clock-icon-container {
  display: flex;
  justify-content: flex-end;
}

.cs-calendar-icon, .cs-clock-icon {
  position: relative;
  bottom: 18px;
  left: 8px;
}

.cs-time {
  color: #6c757d;
  font-size: 13px;
  font-family: SF Pro Display;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 10px;
  width: 6.5vw;
  height: 37px;
  cursor: pointer;
}

.cs-time:focus {
  outline: 0.1px solid #86b7fe;
}

.cs-input-mini {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #6c757d;
  font-size: 13px;
  font-family: SF Pro Display;
  border: 1px solid #D6DAE0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 10px;
  width: 6.5vw;
  height: 37px;
}

.cs-input-mini::placeholder {
  color: #C5C7CC;
  font-size: 13px;
}

.cs-input-mini:focus {
  outline: 0.1px solid #86b7fe;
}

.es-input-mini[type=text] {
  color: #6c757d;
  font-size: 1rem;
  font-family: SF Pro Display;
  display: inline-block;
  box-sizing: border-box;
  padding: 1px 10px 4px 10px;
}

.cs-times-position {
  margin-bottom: -0.4rem;
}

.cs-dropdown-icon, .cs-dropdown-icon-alternative {
  position: absolute;
  transform: translate(-20px, 15px);
}

/* DATE PICKER CUSTOMIZATION */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #3E85F9 !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #3E85F9 !important;
}

.react-datepicker__day--selected {
  background-color: #3E85F9 !important;
  outline: none !important;
}

.react-datepicker__day--today {
  border: 1px solid #3E85F9 !important;
  border-radius: 3px !important;
  font-weight: normal !important;
  height: 27px;
}

.react-datepicker__header {
  background-color: #f4f6fb !important;
  border-bottom: 1px solid #D6DAE0 !important;
}

.react-datepicker__day-name {
  color: #596980 !important;
}

.react-datepicker {
  font-family: SF Pro Display !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #D6DAE0 !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border: none !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: #596980 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 1px;
}

.react-datepicker-time__header {
  margin-top: 0;
  color: #596980 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 1px;
}

.react-datepicker__triangle::before {
  border-top: none !important;
  border-bottom-color: none !important;
}

.react-datepicker__triangle::after {
  border: none !important;
}