.bank-status-top-container {}

.bank-status-top {

  position: relative;
  height: 132px;
  color: rgba(92, 105, 126, 1) !important;
  font-size: 18px;
  font-weight: 400;
}

.bank-status-top-label-container {
  position: relative;
  height: 31px;
  width: 100%;

}

.bank-status-top-bar {
  position: absolute;
  left: 16px;
  height: 70px;
  bottom: 0px;
  width: calc(100% - 32px);
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.bank-status-searched-banks-container {
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: rgba(236, 236, 236, 0.2);
  box-shadow: (0 5px 5px rgba(176, 176, 176, 0.1));
  border: 1px solid rgba(159, 159, 159, 0.679);

  .bank-status-searched-banks-header {
    position: relative;
    height: 44px;

    .bank-status-searched-banks-header-text {
      margin: 12px;
    }

    .bank-status-searched-banks-close {
      border: none;
      background: none;
      padding: 6px;
      color: rgb(150, 150, 150);
      position: absolute;
      right: 0;
    }

  }

  .bank-status-searched-banks-content {}
}


.bank-status-list-container {
  color: rgba(92, 105, 126, 1) !important;
  font-weight: 400;
  position: relative;


  .bank-status-list-header {
    background-color: rgba(244, 246, 251, 1);
    height: 32px;
    display: flex;
    align-items: center;

    .bank-status-list-header-text {
      margin-left: 32px;
      font-size: 12px;
    }

  }

  .bank-status-list-loading {
    background-color: rgba($color: #FFFFFF, $alpha: 0.6);
    width: calc(100% - 16px);
    height: calc(100% - 120px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .bank-status-column {
    transition: all 0.4s ease-in-out;
    position: relative;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 16px 12px;
    border-bottom: 1px solid rgb(230, 230, 230);

    .bank-status-column-loading {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      overflow: hidden;

      .bank-status-column-loading-indicator {
        background-color: rgba(85, 229, 255, 0.783);
        height: 100%;
        width: 20%;
        position: relative;
        transform: translateX(-50%);
        left: 0;
        animation: loadingIndicator 1.8s ease-in-out infinite alternate;
      }


    }

    .bank-status-column-inner {
      font-size: 12px;
      position: relative;
      top: -6px;
      display: flex;
      transition: all 0.4s ease-in-out;
      padding: 12px 12px;
      height: 38px;
      min-height: 38px;
      //width: calc(100% - 24px);
      width: 100%;
      cursor: pointer;
      border-radius: 20px;

      .bank-status-bank-label {
        height: 20px;
        width: calc(100% - 24px);
        left: 12px;
        top: 8px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);

      }

      &:hover .bank-status-bank-label {
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.195);
      }
    }

    .bank-status-column-bar {
      height: 0;
      min-height: 0;
      position: absolute;
      top: 40px;
      width: calc(100% - 24px);
      left: 12px;
      opacity: 0;
      transition: all 0.3s ease-in-out;

    }
  }

  .bank-status-column-expanded {
    height: 86px !important;

    .bank-status-column-inner {
      top: -32px;
      background-color: rgba(0, 0, 0, 0.03);

    }

    .bank-status-column-bar {
      opacity: 1;
      height: 38px;
      min-height: 38px;
    }
  }

}

.bank-status-list-loading-first {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  background-color: rgba($color: #FFFFFF, $alpha: 0.6);

}

@keyframes loadingIndicator {
  0% {
    left: 0;
    width: 0;
  }

  50% {

    width: 70%;
  }

  100% {
    width: 0;
    left: 100%;
  }
}