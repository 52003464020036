.dangerpopup {
  width: 100%;
  top: 50px;
  left: 44.2%;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  max-width: 600px;
  background: #fff;
  border-radius: 10px;
  margin: 0 0 0 -200px;
  box-shadow: 0 0 10px -8px gray;
  border: 1px solid rgb(236, 232, 232);
}

.successpopup_inner {
  display: flex;
  align-items: flex-start;
  border-left: 5px solid #49BC8D;
}

.dangerpopup_inner {
  display: flex;
  align-items: flex-start;
  border-left: 5px solid red;
}

.tick {
  padding: 13px 14px;
}

.close {
  padding: 19px 20px;
}

.flashNameHead {
  width: calc(100% - 120px);
  padding: 14px 0 10px 0;
}

h4.flash-message-heading {
  margin: 0 0 5px;
  line-height: 30px;
}

p.flash-message-info-text {
  margin: 0;
}

.flash-message-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}