.loader-wrapper {
    width: 100vw !important;
    height: 100vh !important;
    display: flex !important;
    position: absolute !important;
    z-index: 100 !important;
    background-color: rgba(0, 0, 0, 0.468) !important;
    align-items: center !important;
  }

  .loader {
    border-top: 4px solid #28A744 !important;
    border-right: 4px solid #28A744 !important;
    border-left: 4px solid #28A744 !important;
    border-bottom: 4px solid transparent !important;
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px !important;
    animation: spin 1s linear infinite !important;
    margin-left: 50% !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

