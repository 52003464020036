.lower-header-it-certificates {
  .dropdown {
    span {
      cursor: pointer;

      img {
        padding: 0 6px;
      }

      &.show {
        img {
          transform: rotate(-180deg);
        }
      }
    }

    ul {
      padding: 0;
      border: none;
      min-width: 150px;
      border-radius: 6px;
      background: #FFFFFF;
      transform: translate(0px, 23px);
      box-shadow: 0px 14px 20px rgba(50, 50, 71, 0.08), 0px 0px 32px rgba(50, 50, 71, 0.08);

      li {
        a {
          color: #596980;
          font-size: 13px;
          padding: 6px 10px;
          line-height: 16px;
        }

        &:first-child a {
          padding: 10px 10px 6px;
        }

        &:last-child a {
          padding: 6px 10px 10px;
        }
      }
    }
  }
}

.search-certificate-id {
  padding: 18px 0 0 0;
  max-width: 250px !important;

  input {
    width: 250px;
    padding: 0 10px !important;
  }
}

.it-certificates-table {
  table {
    tr {
      th {
        font-weight: normal;
      }
    }

    .organizations-tbody {
      td {
        min-width: auto;

        .delete-rad {
          color: #FF647C;
        }
      }
    &.org-user-tbody {
      word-break: break-all;
      td {
        min-width: 180px;
        div {
          word-break: break-word;
        }
      }
    }  
    }
  }
}

.certificate-removal-hard-deletion {
  top: 0px;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 9999;
  position: fixed;
  padding: 20px 0;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .inner-hard-deletion {
    width: 600px;
    height: auto;
    position: static;
    padding: 40px 90px;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(13, 27, 51, 0.1);

    img {
      display: block;
      margin: 0 auto 28px;
    }

    h3 {
      color: #596980;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      text-align: center;
    }

    p {
      color: #596980;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
      margin: 10px 0 32px;
    }

    ul {
      padding: 0;
      margin: 0 0 50px;
      list-style-type: none;

      li {
        color: #000000;
        font-size: 16px;
        line-height: 19px;

        span {
          color: #596980;
          padding: 4px 5px;
          font-weight: normal;
          display: inline-block;
        }
      }
    }

    form {
      .label-form {
        margin: 0 0 30px;

        label {
          color: #596980;
          font-size: 13px;
          margin: 0 0 6px;
          line-height: 16px;

          sup {
            color: #FF647C;
          }
        }

        textarea {
          width: 100%;
          height: 72px;
          display: block;
          font-size: 13px;
          line-height: 16px;
          padding: 12px 10px;
          border-radius: 6px;
          background: #FFFFFF;
          box-sizing: border-box;
          border: 1px solid #D6DAE0;
        }

        input {
          width: 100%;
          height: 37px;
          display: block;
          padding: 0 10px;
          font-size: 13px;
          line-height: 16px;
          line-height: 37px;
          border-radius: 6px;
          background: #FFFFFF;
          border: 1px solid #D6DAE0;
        }
      }
    }

    .label-form-btns {
      display: flex;
      text-align: center;
      justify-content: space-between;

      button {
        width: 166px;
        height: 40px;
        color: #3E85F9;
        line-height: 38px;
        text-align: center;
        border-radius: 6px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #3E85F9;

        &.delete-b {
          color: #ffffff;
          background: #FF647C;
          border: 1px solid #FF647C;
        }

        &.delete-disabled {
          color: #8591A6;
          background: #D6DAE0;
          cursor: not-allowed;
          border: 1px solid #D6DAE0;
        }
      }
    }
  }
}

.deactivate {
  .org-td {
    background-color: rgb(255, 240, 241) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.deactivate-btn {
  background-color: #0065FF;
}

.invite-icon{
  display: inline-block;
  width: 26px;
  margin-right: 5px;
}