.page-container {
  flex: 5;
  height: calc(100vh - 110px);
  background-color: #f4f6fb;
  padding: 10px;
  overflow: auto;
}

.basic-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  color: #596980;
  margin: -10px 20px 20px 0px;
}

.costum-link-btn {
  background-color: #3e85f9;
  color: white;
  border-radius: 6px;
  border-color: transparent;
  height: 38px;
  font-size: 12px;
  padding: 0px 10px;
  margin-top: 10px;
  font-weight: 500;
}

.users-container {
  flex: 5;
  background-color: #fff;
  position: relative;
  height: calc(100vh - 168px);
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
  margin-right: 20px;
  border-radius: 20px;
}

.search-input-group {
  position: relative;
  margin: 0px 0px 20px auto;
}

.search-control {
  width: 360px;
  margin: 0px 0px 0px auto;
  border-radius: 25px;
  height: 37px;
  padding: 20px;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  color: #C6C8CC;
  line-height: 110.4%;
  background-color: #f4f6fb;
  border: none;
  display: block;
  padding: 0 42px 0 17px;
}

.search-control::placeholder {
  font-style: italic;
  font-size: 11px;
}

.search-control:focus {
  background-color: #f4f6fb;
  box-shadow: none;
  outline: none;
  border-color: #f4f6fb !important;
}

.search-icon {
  background: #f4f6fb;
  padding-right: 15px;
  border-radius: 100px;
  padding-left: 1px;
  margin: 0px;
}

.close-icon {
  background: #f4f6fb;
  border-radius: 100px;
  margin: 0px 0px 0px auto;
  width: 37px;
  height: 37px;
  padding-right: 17px;
  padding-left: 7px;
  padding-top: 12px;
  padding-bottom: 12px;

}

.main-table {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
}

.user-category-thread {
  border-bottom: none !important;
  border-top: none !important;
}

.category-thread {
  background-color: #f4f6fb;
  color: #8591a6;
  border-radius: 100px;

}

.category-head {
  cursor: pointer;
}

.category-head,
.user-info {
  padding-left: 30px;
  height: 40px;
}

.user-inforole {
  padding-left: 30px;
  height: 40px;
  text-transform: capitalize;
}

.sort-icon {
  padding-bottom: 2px;
}

.user-data {
  color: #596980;
}

.user-box {
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: #f4f6fb;
}

.remove-icon,
.edit-icon {
  cursor: pointer;
}

.table-scroll-y {
  height: calc(100vh - 296px);
  overflow-y: auto;
}

.table-scroll-y table {
  width: 100%;
}