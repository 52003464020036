div#swagger-editor {
  margin-top: 2%;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  z-index: -1;

  .swagger-editor-standalone .topbar {
    background: #596980;

  }

  a.link:nth-of-type(1) {
    display: none;
  }

  div.dd-menu.dd-menu-left:nth-of-type(6),
  div.dd-menu.dd-menu-left:nth-of-type(5),
  div.dd-menu.dd-menu-left:nth-of-type(4),
  div.topbar-wrapper>div:nth-of-type(3),
  div.dd-menu.dd-menu-left:nth-of-type(2) {
    display: none;
  }

  div.dd-menu.dd-menu-left:nth-of-type(2) {

    li:nth-of-type(2),
    li:nth-of-type(3),
    li:nth-of-type(4) {
      display: none;
    }
  }

  .topbar-wrapper {
    justify-content: flex-start;
  }

  section.container {
    padding: 0;
    margin: 0;
  }

  div.swagger-editor {
    section.container.swagger-container {
      div.SplitPane {
        position: inherit !important;

        .ace-tomorrow-night-eighties .ace_gutter {
          background: #f4f6fb;
          color: #596980;
        }

        .ace-tomorrow-night-eighties .ace_gutter-active-line {
          background-color: #f4f6fb;
        }

        .ace-tomorrow-night-eighties .ace_marker-layer .ace_active-line {
          background: #f4f6fb;
        }
      }
    }

  }

  .ace_print-margin {
    display: none;
  }

  .ace-tomorrow-night-eighties {
    background-color: #f4f6fb;
    color: #596980;
  }

  .ace_string {
    color: #596980;
  }

}

#swagger-editor {
  font-size: 1.3em;
}

#editor-wrapper {
  height: 100%;
  border: none;
  background-color: #f4f6fb;
}