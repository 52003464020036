.transaction {
    position: fixed;
    height: 100%;
    width:100%;
    top: 0px;
    right: 0;
    z-index: 9999; 
    background-color: rgba(0,0,0, 0.5);
    .transaction_inner {
        position: absolute;
        width: 450px;
        height: 265px;
        left: 25%;
        right: 25%;
        top: 25%;
        bottom: 25%;
        margin: auto;
        background: #fff;
        border: 1px solid rgb(236, 232, 232);
        border-radius: 10px;
        box-shadow: gray;
        .main1{
            margin: 15px 85px;
            .bank-title{
                text-align: center;
                font-weight: bold;
                font-size: 20px;
                color: #5b6a83;
                margin-bottom: 10px;
            }
            .title {
                text-align: center;
                font-size: 18px;
                color: #5b6a83;
                margin-top: 10px;
                margin: 0;
            }
            .certificate-no{
                text-align: center;
                font-size: 18px;
                color: #5b6a83;
                font-weight: bold;
            }
        }
        .main{
            margin: 40px 133px;
            .title {
                text-align: center;
                font-size: 18px;
                color: #5b6a83;
                margin-top: 10px;
                margin: 0;
            }
            .certificate-no{
                text-align: center;
                font-size: 18px;
                color: #5b6a83;
                font-weight: bold;
            }
        }
        .btn-div-update{
            align-items: center;
            justify-content: space-around;
            display: flex;
            margin: 0 70px;
            .yes-btn{
                width: 140px;
                height: 36px;
                border-radius: 7px;
                border: 1px solid #3E85F9;
                color:#fff;
                background-color: #3E85F9;
                font-size: 14px;
            }
            .no-btn{
                width: 140px;
                height: 36px;
                border-radius: 7px;
                border: 1px solid #3E85F9;
                color:#3E85F9;
                background-color: white;
                font-size: 14px;
            }
        }
        .btn-div{
            align-items: center;
            justify-content: space-around;
            display: flex;
            margin: 0 60px;
            .yes-btn{
                width: 140px;
                height: 36px;
                border-radius: 7px;
                border: 1px solid #3E85F9;
                color:#fff;
                background-color: #3E85F9;
                font-size: 14px;
            }
            .no-btn{
                width: 140px;
                height: 36px;
                border-radius: 7px;
                border: 1px solid #3E85F9;
                color:#3E85F9;
                background-color: white;
                font-size: 14px;
            }
        }
      }
    .inner{
        height: 285px;
    }
    .update_transaction{
        width: 490px;
    }
  }