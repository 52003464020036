.EditPopup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.EditPopup_inner {
  position: absolute;
  max-width: 500px;
  width: 100%;
  height: 250px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: #fff;
  border: 1px solid rgb(236, 232, 232);
  border-radius: 10px;
  box-shadow: gray;
}

.trash {
  margin-top: 40px;
  margin-left: 150px;
  width: 48px;
  height: 58px;
}

.firstText-update {
  text-align: center;
  font-size: 18px;
  color: #5b6a83;
  margin: 0px;
  line-height: 30px;
}

.secondText-update {
  text-align: center;
  font-size: 18px;
  color: #5b6a83;
  margin: 0px;
  line-height: 30px;
}

.closeDelete-update {
  margin: 10px 0px 0px 50px;
  width: 120px;
  height: 36px;
  color: #3E85F9;
  line-height: 34px;
  border-radius: 7px;
  border: 1px solid #3E85F9;
  background-color: white;
  font-size: 14px;
}

.userName-update {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #5b6a83;
  margin: 0px;
  line-height: 30px;
}

.confirm-update {
  margin: 20px 10px 0px 30px;
  width: 120px;
  height: 36px;
  color: white;
  line-height: 34px;
  border-radius: 7px;
  background-color: #0065FF;
  border: 1px solid #0065FF;
  font-size: 14px;
}

.apply-filter-button {
  height: 40px;
  margin-top: 18px;
  background-color: #3e85f9;
  border: none;
  border-radius: 3px;
  color: white;
}

.open_submit_disabled {
  color: #fff;
  background: #8591a6;
  border-color: #8591a6;
  cursor: not-allowed;
  pointer-events: none;
}

.org-create-user-btn {
  display: flex;
  margin: 15px 15px 0 auto;
  align-items: center;
  background-color: #3e85f9;
  border: none;
  border-radius: 3px;
  color: white;
  height: 40px;
  padding: 5px 10px;
  cursor: pointer;
}