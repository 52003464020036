//#region Healthcheck main css

.ld-header-healthcheck {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inner-page-container-healthcheck {
  flex: 5;
  height: calc(100vh - 110px);
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;
}

.controls-container-healthcheck {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 0;

  .dropdowns-container-healthcheck {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    @media (max-width: 1366px) {
      align-items: flex-start;
    }
  }
}

.ld-form-input-field.disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}


.healthedit-ld-search-container .healthedit-custom-select .select-width-control .ld-form-input-field {
  width: 11rem;

  @media (min-width: 1740px) {
    width: 18.9rem;
  }
}

.healthedit-custom-select {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  min-width: 150px;
  background-color: #555;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 0.9em;
}

.healthedit-custom-select:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}




.searchfield {
  margin-left: 0.5rem;

  @media (max-width: 1366px) {
    width: 100%;
    margin-top: 10px;
  }
}


//#endregion Healthcheck main css

//#region USERS TABLE

.healthcheck-organizationList-thead-scroll-y {
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 418px);
  width: calc(100%);
}

.healthcheck-org-list-table {
  table-layout: fixed;
  width: 100%;
}

.healthcheck-organizations-list-thead {
  background: #F4F6FB;
  color: #8591a6;
  border-bottom: 2px solid #F4F6FB !important;
  border-top: 1px solid #F4F6FB !important;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
}

.healthcheck-org-list-head.name-header,
.healthcheck-org-list-td.name-data {
  width: 20%;
}

.healthcheck-org-list-head.status-header,
.healthcheck-org-list-td.status-data {
  width: 10%;
}

.healthcheck-org-list-head.service-header,
.healthcheck-org-list-td.status-data {
  width: 15%;
}

.healthcheck-org-list-td.status-type {
  width: 15%;
}

.healthcheck-org-list-td.service-data {
  width: 15%;
}

.last-successful-check {
  font-size: 16px; 
}

@media screen and (max-width: 1440px) {
  .last-successful-check {
    font-size: 12px;
  }
}

.healthcheck-org-list-td.country-data {
  width: 10%;
  padding-left: 1rem;
}

.healthcheck-category-head.action-header,
.healthcheck-org-list-td.action-data {
  width: 10%;
  text-align: right;
}

.healthcheck-org-list-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.error-row {
  background-color: #eec5cc;
}


.healthcheck-user-box:hover {
  background-color: #e7e8ed;
}

.healthcheck-user-box .edit-icon {
  position: relative;
}

.sort-icon-hc {
  margin-left: 0.2rem;
}

.statusIdtext {
  font-size: 12px
}

.status-error {
  color: red;
}

.status-ok {
  color: green;
}



//#endregion USERS TABLE

//#region Style for date picker

/* Custom styles for DatePicker */
.custom-date-input {
  background-color: #f8f8f8;
  border: 1px solid #fbfbfb;
  padding: 4px 12px;
  border-radius: 4px;
  width: 17rem;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.date.k-datepickerhealthcheck {
  width: 130%;
}

/* Add more styles as needed */
//#endregion Style for date picker

//#region Style editpage

.form-group-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-control {
  width: 100%;
}

.form-group-healthcheckedit {
  margin-bottom: 1rem;
  width: 18rem;
  max-width: 600px;
}


.labelFormUser {
  font-size: 14px;
  font-weight: 600;
  color: #8591a6;
  margin-bottom: 5px;
}

.edit-icon-editpage {
  margin-left: 2rem;
}

.pagination-bar-healtcheck {
  margin-top: 7rem;
  margin-bottom: 2%;
  align-self: center;
}

.custom-button {
  margin-left: 3rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  border: 1px solid #007bff;
  width: 10rem;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  padding: .375rem .75rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-button:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.full-width-container {
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding-top: 1rem;
  box-sizing: border-box;
}

.clear-date {
  background-color: #f42727;
  color: white;
  border: none;
  border-radius:5px;
  padding: 1.8% 12px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #8e0707;  }
}

.section-search.tab-date {
  justify-content: flex-end;
}
//#endregion Style editpage
