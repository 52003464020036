.documentation-new {
  margin-top: 2%;
  padding: 1em;
  background-color: white;
  border-radius: 10px;
}

form.documentation-form {
  display: flex;
  padding: 1em;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span.input-container {
    min-width: 60%;
    display: flex;
    justify-content: center;

    span.new-documentation-wrapper {
      min-width: 50%;
    }

  }

  span.input-wrapper {
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 5%;


    .add-input {
      border: 1px solid #E3E7EE;
      font-size: 14px;
      border-radius: 5px;
      height: 35px;
      padding: 2%;
    }

    textarea.add-input {
      height: 60px;
    }

    .add-label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #596980;
    }

    textarea::placeholder {
      color: #E3E7EE;

    }

    input::placeholder {
      color: #E3E7EE;

    }

  }
}

.api-popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.468);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-box {
    position: relative;
    margin: 0 auto;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    overflow: auto;
    min-height: 150px;
    border-radius: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    p {
      width: 200px;
      text-align: center;
      color: #596980;
    }
  }
}

.confirm-btn-container {
  display: flex;
  justify-content: center;

  button {
    color: #3e85f9;
    background: white;
    border: 1px solid #3e85f9;
    font-size: 14px;
    width: 120px;
    border-radius: 6px;
    height: 27px;
    margin: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #3e85f9;
    color: white;
  }
}

.update-api-btn {
  height: 35px;
  background-color: #3e85f9;
  border: none;
  border-radius: 3px;
  color: white;
  padding: 0px 5px;
  cursor: pointer;
}